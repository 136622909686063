/* eslint-disable react/no-unknown-property */

import { h } from 'preact';
import SVGWrapper from '../SVGWrapper';

function InfosActive({ height = 60, width = 60, ...props }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <mask id="mask0_9005_41230" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="2" y="2" width="28" height="28">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M30 16C30 23.7325 23.7318 30 16.0004 30C8.26818 30 2 23.7325 2 16C2 8.2675 8.26818 2 16.0004 2C23.7318 2 30 8.2675 30 16ZM16 12.5037C16.7029 12.5037 17.2727 11.9339 17.2727 11.231C17.2727 10.528 16.7029 9.95823 16 9.95823C15.2971 9.95823 14.7273 10.528 14.7273 11.231C14.7273 11.9339 15.2971 12.5037 16 12.5037ZM15.0455 15.3594C15.0455 14.8328 15.4728 14.4059 16 14.4059C16.5272 14.4059 16.9546 14.8328 16.9546 15.3594V21.0888C16.9546 21.6154 16.5272 22.0423 16 22.0423C15.4728 22.0423 15.0455 21.6154 15.0455 21.0888V15.3594Z" fill="black" />
      </mask>
      <g mask="url(#mask0_9005_41230)">
        <rect width="32" height="32" fill="black" />
        <rect width="32" height="32" fill="white" />
      </g>
    </SVGWrapper>
  );
}

export default InfosActive;
