import { Fragment, h } from 'preact';
import { useEffect } from 'preact/hooks';
import { connect } from '../../../ui/hoc';
import Scrollable from '../../../ui/hoc/scrollable';
import Program from './Program';
import { ZAPPING_NAME } from '../../../ui/components/wrappers/Zap/constants';
import { RadioGroup } from '../../../ui/utils/RadioGroup';
import PanelCloseButton from '../panel-close';
import { usePanelCloseCallback } from '../../../ui/components/wrappers/Zap/PanelLiveOption';
import { getList } from '../../../ui/utils/list';

function Programs({ activeTab, list, activeChannel, activeChannelId, id, isExtraLargeTvScreen, currentTab }) {
  const { channels = [] } = list[activeTab] || {};

  useEffect(
    () => new RadioGroup([]),
    [list.length, list.reduce((acc, { channels: chan }) => `${acc},${chan.map(({ id: src }) => src).join(',')}`, '')]
  );

  const close = usePanelCloseCallback();

  useEffect(() => {
    if (!channels.length) close(currentTab);
  }, [channels.length]);

  return (
    <Scrollable forceInnerScroll>
      <Fragment>
        {channels.map((metadata, i) => (
          <Program
            metadata={metadata}
            active={[activeChannelId, id].includes(metadata.id)}
            activeTabMetadata={channels[activeChannel] || null}
            currentTab={currentTab}
            programNumber={i}
            channels={channels}
          />
        ))}
        <PanelCloseButton isExtraLargeTvScreen={isExtraLargeTvScreen} />
      </Fragment>
    </Scrollable>
  );
}

const selector = ({
  media: { duration, id },
  ui: {
    panelLiveOption: { currentTab },
    isExtraLargeTvScreen
  },
  zapping: {
    list,
    current: { tabIndex: activeTab, activeChannel, activeChannelId },
    metaOpened
  },
  playlist: { playlist },
  recommendations: { recommendations }
}) => ({
  currentTab,
  activeTab: currentTab !== ZAPPING_NAME ? 0 : activeTab, /** expecting  better handle on zapping ticket */
  activeChannel,
  metaOpened,
  duration,
  list: getList({ currentTab, list, playlist, recommendations, duration }),
  activeChannelId,
  id,
  isExtraLargeTvScreen
});
export default connect(selector)(Programs);
