import { h } from 'preact';
import SVGWrapper from '../SVGWrapper';

function HeartActiveIcon({ height = 60, width = 60, color = 'white', ...props }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <mask id="mask_heart_active" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="5" y="6" width="22" height="20">
        {/* eslint-disable-next-line react/no-unknown-property */}
        <path d="M14.8941 7.25465C13.3744 6.16065 11.8016 5.99999 11 6C9 6.00002 5 7.7 5 12.5C5 16.9433 11.2838 22.4434 15.1262 25.3515C15.6448 25.744 16.3552 25.744 16.8738 25.3517C20.7163 22.4449 27 16.9547 27 12.5115C27 7.71147 23 6.01147 21 6.01147C20.1972 6.01147 18.6211 6.16519 17.0995 7.26047C16.7256 7.52958 16.355 7.85553 16 8.25C15.6429 7.85325 15.2701 7.52537 14.8941 7.25465Z" fill="black" />
      </mask>
      <g mask="url(#mask_heart_active)">
        <rect width="32" height="32" fill="black" />
        <rect width="32" height="32" fill={color} />
      </g>
    </SVGWrapper>
  );
}

export default HeartActiveIcon;
