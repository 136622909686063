export const ORANGE_MESSAGE_DEFAULT = 'Un problème technique ne permet pas d’accéder au service que vous désirez. Merci de réessayer ultérieurement.<br />Si le problème persiste, rendez-vous sur orange.fr, rubrique assistance, tapez #USER_CODE dans le moteur de recherche. Code erreur : #USER_CODE-#CODE';
export const ORANGE_MESSAGE_TITLE_SERVICE_UNAVAILABLE = 'Accès au service momentanément indisponible';
export const ORANGE_MESSAGE_TITLE_PROGRAM_UNAVAILABLE = 'Accès au programme momentanément indisponible';

export const ORANGE_CDN_IDENTIFIER = 'cdn.orange';

export const replaceCodes = (userCode, code) => ORANGE_MESSAGE_DEFAULT.replaceAll('#USER_CODE', userCode).replaceAll('#CODE', code);

export const detectErrorWithPattern = ({ access: { url } }) => {
  const { host, pathname } = new URL(url);
  const pattern = /^(\/[a-z]{3,}\/[a-z0-9]{1}\/[a-z0-9]{2})?\/[a-z0-9]{10,}/;
  return host.includes(ORANGE_CDN_IDENTIFIER) && !pattern.test(pathname);
};
