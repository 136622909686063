import {
  MEDIA_CHANGED,
  PLAYBACK_DURATIONCHANGE,
  AUDIO_AVAILABLE,
  AUDIO_SWITCHED,
  SUBTITLE_AVAILABLE,
  SUBTITLE_SWITCHED,
  AD_STATUS_CHANGE,
  NEXT_MEDIA,
  UPDATE_MEDIA,
  TITLE_CHANGE,
  TITLE,
  PRE_TITLE,
  ADDITIONAL_TITLE,
  LOGO,
  ON_VIEWERS_UPDATED,
  EMBED_ERROR,
  TIMESHIFTING_CAN_DISPLAY_START_OVER_BUTTON,
  TIMESHIFTING_START_OVER_TIMESHIFTING,
  UPDATE_PROGRAM_MARKERS,
  FULLSCREEN_CHANGED,
  DESCRIPTION_CHANGED
} from '../types';
import { TIMESHIFTING_SHIFTING_TO_START_REQUESTED, TIMESHIFTING_BACK_TO_LIVE_REQUESTED } from '../../core/timeshifting/types';
import { BACKGROUND } from '../../types';

const TITLE_TYPE_MAP = {
  [TITLE]: (title) => ({ videoTitle: title }),
  [PRE_TITLE]: (title) => ({ videoPreTitle: title }),
  [ADDITIONAL_TITLE]: (title) => ({ videoAdditionalTitle: title }),
  [LOGO]: (logo) => ({ videoLogo: logo })
};

const matchTitleByType = (type) => (Object.keys(TITLE_TYPE_MAP).includes(type) ? TITLE_TYPE_MAP[type] : () => ({}));

const initialState = {
  id: null,
  backgroundImage: BACKGROUND,
  spritesheets: {},
  spritesheetsPerBreakpoints: [],
  duration: 0,
  isLive: false,
  isAd: false,
  isDAI: false,
  isStartOverEnabled: false,
  dvr: false,
  embed: false,
  embedError: false,
  subtitlesAvailable: [],
  subtitleSelected: null,
  audiosAvailable: [],
  audioSelected: null,
  videoTitle: '',
  videoPreTitle: '',
  videoAdditionalTitle: '',
  videoLogo: '',
  metaReady: null,
  shares: null,
  adType: '',
  loaded: false,
  comingNext: {},
  isDVR: false,
  expectedStartOverDuration: null,
  numberViewers: null,
  broadcastedAt: '',
  timeshifting: {
    type: null,
    startOverTimeshifting: false,
    isAbleToStartOver: false
  },
  programMarkers: [],
  imagesEpg: [],
  isEPGDirty: false,
  skipIntro: {
    duration: null,
    timeBeforeDismiss: null,
    timecode: null
  },
  skipRecap: {
    duration: null,
    timeBeforeDismiss: null,
    timecode: null
  },
  isHighlightable: false,
  isAudio: false,
  chat: null,
  description: null,
  playtime: null,
  endDate: null
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case NEXT_MEDIA: {
      const { loaded } = payload;
      return {
        ...state,
        loaded
      };
    }
    case (MEDIA_CHANGED): {
      const {
        isLive,
        isDAI,
        isDVR,
        isStartOverEnabled,
        id,
        title,
        preTitle,
        additionalTitle,
        logo,
        backgroundImage,
        resource,
        spritesheets = [],
        loaded,
        comingNext,
        embed,
        timeshifting,
        broadcastedAt,
        name,
        skipIntro,
        skipRecap,
        markers,
        isFullscreen,
        isTv,
        isHighlightable,
        isAudio,
        chat,
        description: descriptionFromServer,
        playtime,
        endDate
      } = payload;

      const spritesheetsPerBreakpoints = spritesheets.sort((a, b) => (a.width + a.height < b.width + b.height ? -1 : 1));
      const [windowedSpritesheets = {}, fullscreenedSpritesheets = {}] = spritesheetsPerBreakpoints;
      const description = state.description ?? descriptionFromServer;

      return {
        ...state,
        isLive,
        isDAI,
        isDVR,
        isStartOverEnabled,
        comingNext,
        id,
        backgroundImage,
        resource,
        videoTitle: title,
        videoPreTitle: preTitle,
        videoAdditionalTitle: additionalTitle,
        videoLogo: logo,
        subtitlesAvailable: [],
        subtitleSelected: -1 /* default to no-subtitles */,
        audiosAvailable: [],
        audioSelected: 0,
        spritesheetsPerBreakpoints,
        spritesheets:
          isFullscreen && spritesheetsPerBreakpoints.length > 1
            ? fullscreenedSpritesheets
            : windowedSpritesheets,
        loaded,
        embed,
        startOverLive: false,
        embedError: false,
        timeshifting,
        broadcastedAt,
        name,
        skipIntro,
        skipRecap,
        markers,
        isHighlightable,
        isTv,
        isAudio,
        chat,
        description,
        playtime,
        endDate
      };
    }

    case UPDATE_MEDIA: {
      return { ...state, ...payload };
    }

    case DESCRIPTION_CHANGED: {
      return { ...state, description: payload };
    }

    case FULLSCREEN_CHANGED: {
      const { isFullscreen } = payload;
      const { spritesheetsPerBreakpoints } = state;
      const [
        windowedSpritesheets = {},
        fullscreenedSpritesheets = {}
      ] = spritesheetsPerBreakpoints;

      return {
        ...state,
        spritesheets:
          isFullscreen && spritesheetsPerBreakpoints.length > 1
            ? fullscreenedSpritesheets
            : windowedSpritesheets
      };
    }
    case PLAYBACK_DURATIONCHANGE: {
      const { duration } = payload;
      return { ...state, duration };
    }
    case (AUDIO_AVAILABLE): {
      const { audiosAvailable, audioSelected } = payload;
      return { ...state, audiosAvailable, audioSelected };
    }
    case (AUDIO_SWITCHED): {
      const { audioSelected } = payload;
      return { ...state, audioSelected };
    }
    case (SUBTITLE_AVAILABLE): {
      const { subtitlesAvailable, subtitleSelected } = payload;
      return { ...state, subtitlesAvailable, subtitleSelected };
    }
    case (SUBTITLE_SWITCHED): {
      const { subtitleSelected } = payload;
      return { ...state, subtitleSelected };
    }
    case (AD_STATUS_CHANGE): {
      const { isAd, isVpaid } = payload;
      return { ...state, isAd, isVpaid };
    }
    case (TITLE_CHANGE): {
      const { titles } = payload;
      return {
        ...state,
        videoTitle: null,
        videoPreTitle: null,
        videoAdditionalTitle: null,
        // videoLogo: null,
        ...Object.entries(titles).reduce((acc, [titleType, value]) => ({ ...acc, ...matchTitleByType(titleType)(value) }), {})
      };
    }

    case ON_VIEWERS_UPDATED: {
      const { numberViewers } = payload;
      return ({
        ...state,
        numberViewers
      });
    }

    case EMBED_ERROR: {
      const { embedError, message } = payload;
      return ({
        ...state,
        embedError,
        message
      });
    }

    case TIMESHIFTING_CAN_DISPLAY_START_OVER_BUTTON:
    case TIMESHIFTING_SHIFTING_TO_START_REQUESTED:
    case TIMESHIFTING_BACK_TO_LIVE_REQUESTED:
    case TIMESHIFTING_START_OVER_TIMESHIFTING: {
      let { timeshifting } = state;
      timeshifting = { ...timeshifting, ...payload };
      return ({ ...state, timeshifting });
    }

    case UPDATE_PROGRAM_MARKERS: {
      const { programMarkers, imagesEpg, isEPGDirty } = payload;
      return ({ ...state, programMarkers, imagesEpg, isEPGDirty });
    }

    default: {
      return state;
    }
  }
};
