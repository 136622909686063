import { h } from 'preact';
import { useEffect, useRef } from 'preact/hooks';
import { connect } from '../../../ui/hoc';
import TrackList from '../tracks/track-list';
import { TV_SIDE_OVERLAY_WRAPPER, TV_SIDE_OVERLAY_WRAPPER_TRACKS } from './styles';
import ProgramList from '../programlist';
import { usePlayerContext } from '../../../ui/hooks';
import { USER_KEYBOARD, USER_PANEL_CLOSED, USER_PANEL_OPENED } from '../../../types';
import { INFOS_NAME, PLAYLIST_NAME, TRACKS_NAME, ZAPPING_NAME } from '../../../ui/components/wrappers/Zap/constants';
import InfosPanel from '../info/InfosPanel';

function TVSideOverlay(props) {
  const {
    subtitlesAvailable,
    subtitleSelected,
    audiosAvailable,
    audioSelected,
    panelLiveOption
  } = props;
  const player = usePlayerContext();
  const renderCount = useRef(0);

  useEffect(() => {
    /* We skip event emitter for the 1st render */
    if (renderCount.current > 0) {
      const tabName = panelLiveOption.show ? panelLiveOption.currentTab : panelLiveOption.lastTab;
      player.userEvents$.next({
        action: panelLiveOption.show ? USER_PANEL_OPENED : USER_PANEL_CLOSED,
        feature: tabName,
        value: { name: tabName, showPanel: false },
        source: USER_KEYBOARD,
        ...(panelLiveOption.show
          // eslint-disable-next-line react/destructuring-assignment
          ? { itemsCount: props[tabName]?.length ?? null }
          : { button: null })
      });

      if ([panelLiveOption.currentTab, panelLiveOption.lastTab].includes(TRACKS_NAME)) {
        player.panelController.isTrackViewActive$.next(panelLiveOption.show);
      }
    }
  }, [panelLiveOption.currentTab, panelLiveOption.show]);

  if (!panelLiveOption.currentTab || !panelLiveOption.show) return null;
  renderCount.current += 1;

  let content = <ProgramList panelLiveOption={panelLiveOption} />;
  switch (panelLiveOption.currentTab) {
    case INFOS_NAME:
      content = <InfosPanel />;
      break;
    case TRACKS_NAME:
      content = (
        <TrackList
          subtitlesAvailable={subtitlesAvailable}
          subtitleSelected={subtitleSelected}
          audiosAvailable={audiosAvailable}
          audioSelected={audioSelected}
        />
      );
      break;
    default:
      break;
  }

  return (
    <div
      style={
        panelLiveOption.currentTab === TRACKS_NAME
          ? TV_SIDE_OVERLAY_WRAPPER_TRACKS
          : TV_SIDE_OVERLAY_WRAPPER
      }
    >
      {content}
    </div>
  );
}

const selector = ({
  media: {
    subtitlesAvailable,
    subtitleSelected,
    audiosAvailable,
    audioSelected
  },
  ui: { panelLiveOption },
  zapping: { list },
  playlist: { playlist }
}) => ({
  subtitlesAvailable,
  subtitleSelected,
  audiosAvailable,
  audioSelected,
  panelLiveOption,
  [PLAYLIST_NAME]: playlist,
  [ZAPPING_NAME]: list
});

export default connect(selector)(TVSideOverlay);
