import { OVERLAY_COLOR_OPAQUE, SECONDARY_FILL_COLOR, PRIMARY_FILL_COLOR, FTV_DARK_PRIMARY_BACKGROUND_COLOR } from '../../../theme/colors';
import {
  FTV_TIMING_FUNCTION_OUT,
  BUTTON_LAYER,
  DIALOG_LAYER,
  DIALOG_BUTTON_LAYER,
  SKIP_BTN_LAYER
} from '../../../theme/constants';

import { LABEL_TEXT_FONT_SIZE } from '../../../styles';

export const DIALOG_BUTTON_TRANSITION_DURATION = 250;

export const BUTTON_RESET = {
  cursor: 'pointer',
  border: 0,
  margin: 0,
  background: 'transparent',
  outline: 'none',
  fontFamily: 'inherit',
  fontSize: 'inherit',
  lineHeight: 'inherit',
  color: 'inherit',
  flexShrink: 0
};

export const DEFAULT_BUTTON_STYLE = {
  ...BUTTON_RESET,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  pointerEvents: 'initial',
  position: 'relative'
};

export const DIALOG_CLOSE_BUTTON_STYLE = {
  position: 'absolute',
  top: 33,
  right: 35,
  zIndex: DIALOG_BUTTON_LAYER,
  justifyContent: 'flex-end'
};

export const DEFAULT_BUTTON_SIZE = 45; /* 40px svg + 10px 10px margin */
export const LABELED_ICON_WRAPPER_STYLE = { display: 'flex', alignItems: 'center' };

export const LABELED_BUTTON_STYLE = {
  default: {
    height: 46,
    lineHeight: '46px',
    padding: '8px 20px',
    borderRadius: '24px',
    fontSize: 16,
    marginTop: '1%',
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    color: SECONDARY_FILL_COLOR,
    textAlign: 'center',
    textDecoration: 'none',
    fontFamily: 'Brown',
    fontWeight: 400
  },
  extraLarge: {
    height: 44,
    lineHeight: '44px',
    fontSize: 16
  },

  large: {
    height: 44,
    lineHeight: '44px',
    fontSize: 16
  },
  medium: {
    height: 36,
    lineHeight: '36px',
    fontSize: 14,
    padding: '8px 16px'
  },
  small: {
    height: 32,
    lineheight: '32px',
    fontSize: 12
  },
  extraSmall: {
    height: 32,
    lineHeight: '32px',
    fontSize: 12
  },
  'largeTv+extraLargeTv': {
    color: PRIMARY_FILL_COLOR
  }
};

export const FEEDBACK_SVG_WRAPPER = {
  position: 'absolute',
  display: 'flex',
  top: 0,
  left: 0,
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  pointerEvents: 'none',
  fontFamily: 'Brown',
  fontWeight: 700
};

export const TOGGLE_BUTTON_BAR = {
  'extraSmall+small': {
    width: 34,
    height: 16
  },
  'medium+large+extraLarge': {
    width: 42,
    height: 20
  },
  default: {
    borderRadius: 40,
    display: 'block',
    position: 'relative',
    cursor: 'pointer',
    transition: 'all .15s ease-in-out',
    backgroundColor: 'white'
  },
  'largeTv+extraLargeTv': {
    backgroundColor: PRIMARY_FILL_COLOR
  }
};

export const TOGGLE_BUTTON_HANDLE = {
  'extraSmall+small': {
    height: 16,
    width: 16
  },
  'medium+large+extraLarge': {
    height: 20,
    width: 20
  },
  default: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    background: 'white',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.5)',
    transition: 'all .15s ease-in-out'
  }
};

export const TOGGLE_CHECK_ICON_SIZE = {
  'medium+large+extraLarge': { width: 10, height: 7 },
  'extraSmall+small': { width: 8, height: 5, style: { position: 'relative', left: '1px' } }
};
export const BUTTON_LABEL_WRAPPER_STYLES = {
  position: 'absolute',
  zIndex: BUTTON_LAYER,
  width: 'auto',
  pointerEvents: 'none', /* don't mess with mouse events */
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

export const BUTTON_LABEL_TEXT_STYLES = {
  ...LABEL_TEXT_FONT_SIZE,
  default: {
    padding: '7px 10px 7px',
    textAlign: 'center',
    display: 'block',
    fontFamily: 'Brown',
    fontWeight: 700,
    fontSize: 12,
    lineHeight: '12px',
    whiteSpace: 'nowrap',
    color: '#000000'
  }
};

export const BUTTON_LABEL_STYLES = {
  position: 'absolute',
  opacity: 0,
  overflow: 'hidden',
  textAlign: 'center',
  width: 'auto',
  background: PRIMARY_FILL_COLOR,
  bottom: 0,
  borderRadius: 15
};

export const DEFAULT_DIALOG_CONTENT_RESPONSIVE_STYLES = {
  background: OVERLAY_COLOR_OPAQUE,
  transform: 'translateX(120%)', /* account for padding */
  transitionProperty: 'transform, opacity',
  transitionDuration: `${DIALOG_BUTTON_TRANSITION_DURATION}ms`,
  transitionTimingFunction: FTV_TIMING_FUNCTION_OUT,
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  opacity: 0,
  overflow: 'hidden',
  zIndex: DIALOG_LAYER
};

export const DIALOG_CONTENT_TV_STYLES = {
  background: OVERLAY_COLOR_OPAQUE,
  transform: 'translateX(120%)', /* account for padding */
  transitionProperty: 'transform, opacity',
  transitionDuration: `${DIALOG_BUTTON_TRANSITION_DURATION}ms`,
  transitionTimingFunction: FTV_TIMING_FUNCTION_OUT,
  width: '30%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: '70%',
  opacity: 0,
  overflow: 'hidden',
  zIndex: DIALOG_LAYER
};

export const DEFAULT_DIALOG_OPENED_CONTENT_RESPONSIVE_STYLES = {
  opacity: 1,
  transform: 'translateX(0)'
};

export const SKIP_BTN_LAYER_STYLE = {
  default: {
    position: 'absolute',
    width: '100%',
    bottom: 135,
    right: 30,
    display: 'flex',
    justifyContent: 'flex-end',
    height: '50px',
    zIndex: SKIP_BTN_LAYER,
    cursor: 'pointer'
  },
  large: {
    bottom: 157,
    right: 48
  },
  extraLarge: {
    bottom: 172,
    right: 48
  },
  small: {
    bottom: 69,
    right: 18
  },
  mobile: {
    bottom: 80,
    padding: '0 18px'
  },
  tablet: {
    padding: '0 18px'
  },
  extraSmall: {
    display: 'none'
  },
  'largeTv+extraLargeTv': {
    position: 'absolute',
    right: 80,
    bottom: 16,
    left: 'inherit',
    width: 'auto',
    padding: 0,
    zIndex: SKIP_BTN_LAYER,
    height: '72px'
  }
};

export const SKIP_BTN_STYLE_FOCUS_HOVER = {
  'largeTv+extraLargeTv': {
    backgroundColor: '#f0f1f3d9' // d9 85% alpha
  }
};

export const SKIP_BTN_STYLE = {
  default: {
    backgroundColor: '#232323',
    border: '1px solid rgba(255, 255, 255, 0.5)',
    borderRadius: '20px',
    width: '140px',
    height: '40px',
    color: '#FFFFFF',
    textAlign: 'center',
    textDecoration: 'none',
    cursor: 'pointer',
    padding: 0,
    margin: 0,
    transform: 'none',
    fontFamily: 'Brown',
    fontSize: '16px',
    fontWeight: 400
  },
  'largeTv+extraLargeTv': {
    border: '1px solid #f0f1f3',
    borderRadius: '72px',
    height: '72px',
    color: '#000000',
    padding: '24px 36px',
    width: 'auto',
    textAlign: 'center',
    textDecoration: 'none',
    cursor: 'pointer',
    margin: 0,
    backgroundColor: FTV_DARK_PRIMARY_BACKGROUND_COLOR,
    transform: 'none',
    fontFamily: 'Brown',
    fontSize: '30px',
    fontWeight: 400,
    display: 'flex'
  }
};

export const SKIP_BTN_INTRO_OVERRIDE = {
  'largeTv+extraLargeTv': {
    width: 'auto'
  }
};

export const DEFAULT_DIALOG_OVERLAY_PADDING = '27px 30px 15px 48px';
export const LIGHT_DIALOG_OVERLAY_PADDING = '27px 30px 15px';
