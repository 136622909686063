/**
 * Controllers inheriting from TracksController must implement :
 * setTrack(renderer, track):string -> sets track on renderer and returns lang
 *
 * About the RECONCILIER :
 * The goal of the reconcilier is to link the availableTracks$ stream with the actual
 * tracks present in the DOM (audio or text). For it to work efficiently, it must be
 * "renderer-agnostic" and "track-type-agnostic". We need to make sure all the different renderers
 * expose their tracks in the exact same way : { index, lang, kind }.
 * The reconcilier will simplify the way we deal with tracks in the code base.
 */

import { Subject, ReplaySubject, combineLatest, merge, NEVER } from 'rxjs';
import { share, withLatestFrom, filter, map, take, skip } from 'rxjs/operators';
import { getLanguagePriority, mapTracks } from '../../utils/tracks';
import { Disposable } from '..';
import { TV_PLATFORMS } from '../media/types';

class TracksController extends Disposable {
  constructor({ tracks$, activeRenderer$, isAd$, events$, platform }) {
    super();
    this.nextTrack$ = new Subject();
    this.activeTrack$ = new Subject();
    this.activeTrackLabel$ = new ReplaySubject(1);
    this.availableTracks$ = new ReplaySubject(1);

    tracks$.pipe(
      map((tracks) => tracks.sort((a, b) => getLanguagePriority(a.lang) - getLanguagePriority(b.lang))),
      share()
    ).subscribe(this.availableTracks$);

    this.createActiveTrackWithLabel()
      .subscribe(this.activeTrackLabel$);

    TracksController.createTrackIndexRestoreStream({
      isAd$,
      events$,
      activeTrack$: this.activeTrack$,
      availableTracks$: this.availableTracks$,
      platform
    }).subscribe(this.activeTrack$);

    TracksController.createTrackIndexGuardStream({ nextTrack$: this.nextTrack$, availableTracks$: this.availableTracks$ })
      .subscribe(this.activeTrack$);

    this.activeTrack$
      .pipe(withLatestFrom(activeRenderer$, (track, { renderer }) => ({ track, renderer })))
      .subscribe(({ track, renderer }) => this.setTrack(renderer, track)); /* Delegate track selection to renderer */
  }

  createActiveTrackWithLabel() {
    return this.activeTrack$.pipe(
      withLatestFrom(this.availableTracks$.pipe(mapTracks())),
      map(([active, tracks]) => this.findTrack(tracks, active))
    );
  }

  nextTrack(index) {
    this.nextTrack$.next(index);
  }

  static createReconcilerStream({ type, availableTracks$, activeRenderer$ }) {
    return availableTracks$.pipe(
      filter((tracks) => tracks.length),
      withLatestFrom(activeRenderer$, (tracks, { renderer }) => ({ tracks, videoEl: renderer.tagElement, type })),
      filter(({ videoEl }) => Boolean(videoEl))
    );
  }

  static reconcile({ type, tracks, videoEl }) {
    const domTracks = Object.values(videoEl[type] || {}); /* tracks may be undefined */

    domTracks.forEach((domTrack) => {
      const match = tracks.find(({ lang, kind }) => (
        (lang === domTrack.language)
        && (kind === domTrack.kind)
      ));
      /* mutate TextTrack object by adding the index property and a custom flag */
      domTrack.index = match ? match.index : null; /* eslint-disable-line */
    });
  }

  static createTrackIndexGuardStream({ nextTrack$, availableTracks$ }) {
    return merge(
      combineLatest(nextTrack$, availableTracks$).pipe(take(1)),
      nextTrack$.pipe(withLatestFrom(availableTracks$)).pipe(skip(1))
    ).pipe(
      filter(
        ([nextTrackIndex, tracks]) => !tracks.length || nextTrackIndex === -1
          || tracks.reduce(
            (isIn, { index }) => isIn || index === nextTrackIndex,
            false
          )
      ),
      map(([nextTrackIndex]) => nextTrackIndex)
    );
  }

  static createTrackIndexRestoreStream({ isAd$, activeTrack$, availableTracks$, platform }) {
    if (!TV_PLATFORMS.includes(platform)) return NEVER;

    return availableTracks$.pipe(
      withLatestFrom(isAd$, activeTrack$),
      filter(([, { isAd }]) => !isAd),
      map(([, , activeTrack]) => activeTrack)
    );
  }
}

export default TracksController;
