/* eslint-disable react/no-unknown-property */
import { h } from 'preact';
import SVGWrapper from '../../SVGWrapper';

function TvInfoIcon({ height = 32, width = 32 }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
    >
      <mask id="mask0_9005_41228" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="5" y="5" width="22" height="22">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16 14.7476C15.5858 14.7476 15.25 15.083 15.25 15.4967V19.9984C15.25 20.4122 15.5858 20.7476 16 20.7476C16.4142 20.7476 16.75 20.4122 16.75 19.9984V15.4967C16.75 15.083 16.4142 14.7476 16 14.7476Z" fill="black" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17 12.2529C17 12.8052 16.5523 13.2529 16 13.2529C15.4477 13.2529 15 12.8052 15 12.2529C15 11.7006 15.4477 11.2529 16 11.2529C16.5523 11.2529 17 11.7006 17 12.2529Z" fill="black" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.0003 25.5C21.2466 25.5 25.5 21.2471 25.5 16C25.5 10.7529 21.2466 6.5 16.0003 6.5C10.7534 6.5 6.5 10.753 6.5 16C6.5 21.247 10.7534 25.5 16.0003 25.5ZM16.0003 27C22.075 27 27 22.0755 27 16C27 9.92447 22.075 5 16.0003 5C9.925 5 5 9.92447 5 16C5 22.0755 9.925 27 16.0003 27Z" fill="black" />
      </mask>
      <g mask="url(#mask0_9005_41228)">
        <rect width="32" height="32" fill="black" />
        <rect width="32" height="32" fill="white" />
      </g>
    </SVGWrapper>
  );
}

export default TvInfoIcon;
