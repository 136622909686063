import { Fragment, h } from 'preact';
import { connect } from '../../../ui/hoc';
import { COMING_NEXT, NEXT, USER_REQUEST_MEDIA_LOAD } from '../../../types';
import { UI_SKIP_INTRO_CLICKED, UI_SKIP_RECAP_CLICKED } from '../../../store/types';
import { SKIP_BTN_INTRO_OVERRIDE } from '../../../ui/components/common/buttons/styles';
import { TVSkipButton } from '../common/skipbutton';
import { mapTitleMetadata } from '../../../ui/utils/tools';

function TVSkipButtonContainer({
  isAd,
  skipIntroDuration,
  skipIntroTimeCode,
  showSkipIntro,
  showSkipRecap,
  skipRecapDuration,
  skipRecapTimeCode,
  showComingNext,
  next,
  title,
  preTitle,
  additionalTitle,
  playlist,
  showNextPlaylistBtn,
  currentPlaylistVideo
}) {
  const getNextClickEvent = (isNext, isPlaylist) => {
    if (isNext) {
      return { name: NEXT, payload: COMING_NEXT };
    }

    if (isPlaylist) {
      const nextPlaylistVideo = currentPlaylistVideo + 1;
      const tracking = {
        /* position is the human readable version of the index, hence the +1 */
        diffusion: { mode: 'tunnel', position: nextPlaylistVideo + 1, length: playlist.length },
        tracking: {
          pageProvenance: '',
          zoneProvenance: 'playlist_player',
          positionVignette: '',
          playProvenance: 'playlist'
        },
        startTimecode: 0,
        ...mapTitleMetadata(playlist[nextPlaylistVideo], { title, preTitle, additionalTitle })
      };
      return {
        action: USER_REQUEST_MEDIA_LOAD,
        value: {
          src: playlist[nextPlaylistVideo].src,
          config: {
            ...playlist[nextPlaylistVideo].config,
            intro: false,
            autostart: true,
            ...tracking
          }
        }
      };
    }
    return {};
  };

  return (
    <Fragment>
      {(!isAd && (
      <TVSkipButton
        duration={skipIntroDuration}
        timecode={skipIntroTimeCode}
        label="passer le générique"
        show={showSkipIntro}
        style={SKIP_BTN_INTRO_OVERRIDE}
        clickEvent={UI_SKIP_INTRO_CLICKED}
      />
      )) }
      {(!isAd && (
      <TVSkipButton
        duration={skipRecapDuration}
        timecode={skipRecapTimeCode}
        label="passer le récap"
        show={showSkipRecap}
        clickEvent={UI_SKIP_RECAP_CLICKED}
      />
      )) }
      {(!isAd && (next || showNextPlaylistBtn) && (
      <TVSkipButton
        label="vidéo suivante"
        show={showComingNext}
        clickEvent={getNextClickEvent(next, showNextPlaylistBtn)}
        seek={false}
      />
      ))}
    </Fragment>
  );
}

const selector = ({
  playlist: { playlist, playlistEnabled = Boolean(playlist.length) },
  media: {
    id,
    isAd,
    skipIntro: { timecode: skipIntroTimeCode, duration: skipIntroDuration },
    skipRecap: { timecode: skipRecapTimeCode, duration: skipRecapDuration },
    title,
    preTitle,
    additionalTitle
  },
  ui: { showSkipIntro, showSkipRecap, showComingNext, next }
}) => ({
  isAd,
  skipIntroDuration,
  skipIntroTimeCode,
  showSkipIntro,
  showSkipRecap,
  skipRecapDuration,
  skipRecapTimeCode,
  showComingNext,
  next,
  playlist,
  showNextPlaylistBtn: playlistEnabled && playlist[playlist.length - 1].src !== id,
  currentPlaylistVideo: playlist.findIndex((v) => v.src === id),
  title,
  preTitle,
  additionalTitle
});

const TVSkipButtonWrapper = connect(selector)(TVSkipButtonContainer);
export {
  TVSkipButtonWrapper
};
