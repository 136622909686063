import { RECO_CHANGED, RECO_DISPLAY_CHANGED, RECO_HIDE_VIDEO, RECOMMENDATIONS_CHANGED } from '../types';

const initialState = {
  hideVideo: false,
  shouldDisplay: false,
  cause: 'noReco',
  isLegacy: false,
  recos: [{
    image: '',
    badge: '',
    iconChannel: '',
    iconCsa: '',
    title: '',
    synopsis: '',
    subTitle: '',
    duration: '',
    metadata: ''
  }],
  recommendations: []
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case (RECO_DISPLAY_CHANGED): {
      const { shouldDisplay, cause } = payload;
      return { ...state, shouldDisplay, cause, isLegacy: true };
    }
    case RECO_CHANGED: {
      const { recos, isLegacy } = payload;
      return { ...state, recos, isLegacy };
    }
    case RECOMMENDATIONS_CHANGED: {
      const { recommendations, isLegacy } = payload;
      return { ...state, recommendations, isLegacy };
    }
    case RECO_HIDE_VIDEO: {
      const { hideVideo } = payload;
      return { ...state, hideVideo };
    }
    default: {
      return state;
    }
  }
};
