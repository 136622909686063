/* eslint-disable react/no-unknown-property */

import { h } from 'preact';
import SVGWrapper from '../SVGWrapper';

function Infos({ height = 60, width = 60, ...props }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <mask id="mask0_9005_41216" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="2" y="2" width="28" height="28">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16 14.406C15.4728 14.406 15.0455 14.8329 15.0455 15.3594V21.0889C15.0455 21.6155 15.4728 22.0423 16 22.0423C16.5272 22.0423 16.9545 21.6155 16.9545 21.0889V15.3594C16.9545 14.8329 16.5272 14.406 16 14.406Z" fill="black" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.2727 11.231C17.2727 11.9339 16.7029 12.5037 16 12.5037C15.2971 12.5037 14.7273 11.9339 14.7273 11.231C14.7273 10.5281 15.2971 9.95827 16 9.95827C16.7029 9.95827 17.2727 10.5281 17.2727 11.231Z" fill="black" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.0004 28.0909C22.6775 28.0909 28.0909 22.6781 28.0909 16C28.0909 9.32189 22.6775 3.90909 16.0004 3.90909C9.32245 3.90909 3.90909 9.32197 3.90909 16C3.90909 22.678 9.32245 28.0909 16.0004 28.0909ZM16.0004 30C23.7318 30 30 23.7325 30 16C30 8.2675 23.7318 2 16.0004 2C8.26818 2 2 8.2675 2 16C2 23.7325 8.26818 30 16.0004 30Z" fill="black" />
      </mask>
      <g mask="url(#mask0_9005_41216)">
        <rect width="32" height="32" fill="black" />
        <rect width="32" height="32" fill="white" />
      </g>
    </SVGWrapper>
  );
}

export default Infos;
