import { h } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';
import { connect } from '../../../ui/hoc';
import { useStyles } from '../../../ui/hooks';
import {
  INFOS_NAME,
  TITLES_MAPPING
} from '../../../ui/components/wrappers/Zap/constants';
import { TV_PANEL_LIVE_OPTION_WRAPPER_STYLE_OPENED } from '../programlist/styles';
import {
  PANEL_PROGRAM_DESCRIPTION,
  PANEL_PROGRAM_METADATA,
  PANEL_TITLE
} from '../../../ui/components/wrappers/Zap/styles';
import PanelCloseButton from '../panel-close';

function InfosPanel({
  panelLiveOption,
  height,
  isExtraLargeTvScreen,
  description,
  playtime,
  endDate
}) {
  const setStyles = useStyles();

  /*
    We compute the size available for the description :
    We remove from the total height of the player :
     * The height of the Panel name
     * The footer (duration + close button)
    We also remove the padding from the description itself.
    That gives us the max allow height for it.
  */
  const panelNameRef = useRef({ clientHeight: 0 });
  const footerRef = useRef({ clientHeight: 0 });
  const [descriptionMaxHeight, setDescriptionMaxHeight] = useState(0);

  useEffect(
    () => {
      setDescriptionMaxHeight(
        height
        - footerRef.current.clientHeight - panelNameRef.current.clientHeight
        - setStyles(PANEL_PROGRAM_DESCRIPTION).padding * 2
      );
    },
    [footerRef.current.clientHeight]
  );
  /***/

  return (
    <div style={setStyles(TV_PANEL_LIVE_OPTION_WRAPPER_STYLE_OPENED)}>
      <div
        id="panel"
        style={{
          display: 'block'
        }}
      >
        <div style={setStyles(PANEL_TITLE)} ref={panelNameRef}>
          {TITLES_MAPPING[panelLiveOption.currentTab]
            || TITLES_MAPPING[INFOS_NAME]}
        </div>
        <div name="infos-view">
          <div
            style={{ ...setStyles(PANEL_PROGRAM_DESCRIPTION) }}
          >
            <div style={{ maxHeight: descriptionMaxHeight, textOverflow: 'ellipsis', overflow: 'hidden' }}>{description}</div>
          </div>
          <div name="infos-view-footer" ref={footerRef}>
            <div style={setStyles(PANEL_PROGRAM_METADATA)}>
              {playtime}
              {' '}
              | disponible jusqu&rsquo;au
              {' '}
              {endDate}
            </div>
            <div>
              <PanelCloseButton isExtraLargeTvScreen={isExtraLargeTvScreen} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const selector = ({
  ui: { panelLiveOption, height, isExtraLargeTvScreen },
  media: {
    description,
    playtime,
    endDate
  }
}) => ({
  panelLiveOption,
  height,
  isExtraLargeTvScreen,
  description,
  playtime,
  endDate
});

export default connect(selector)(InfosPanel);
