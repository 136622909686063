import { youbora } from '../../configuration';
import { FREEWHEEL_AD } from '../../ad/freewheel/types';
import Npaw from '../../monitoring/npaw';
import { MONITORING_OPTIN } from './types';
import { Disposable } from '..';

export default class NpawController extends Disposable {
  constructor(player, config) {
    super();
    this.player = player;
    const { consent: { npaw }, publicId } = config;

    this.npaw = new Npaw({
      player: this.player, config: {
        ...youbora,
        ...(npaw === MONITORING_OPTIN ? { username: publicId } : {})
      }
    });
    this.ad = {};
  }

  async init() {
    this.ad = await this.player.freewheelController.setup;
    this.npaw.configure({ player: this.player, ad: this.ad.type === FREEWHEEL_AD });
  }

  dispose() {
    // Delay dispose for Npaw to last
    setTimeout(() => this.npaw.dispose(), 300);
    super.dispose();
  }
}
