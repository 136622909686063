import media from './media';
import playback from './playback';
import volume from './volume';
import ad from './ad';
import ui from './ui';
import error from './error';
import warning from './warning';
import systemInfo from './systemInfo';
import zapping from './zapping';
import settings from './settings';
import recommendations from './recommendations';
import highlights from './highlights';
import playlist from './playlist';
import subtitles from './subtitles';

export function compose(reducers) {
  const branches = Object.keys(reducers);
  return (state = {}, action) => branches.reduce((currentState, key) => {
    const reducer = reducers[key];
    return {
      ...currentState,
      [key]: reducer(currentState[key], action)
    };
  }, state);
}

export default compose({
  playback,
  volume,
  media,
  ad,
  ui,
  error,
  warning,
  systemInfo,
  zapping,
  settings,
  recommendations,
  highlights,
  playlist,
  subtitles
});
