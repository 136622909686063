import { h } from 'preact';
import { useCallback, useMemo } from 'preact/hooks';
import { connect } from '../../../ui/hoc';
import { TVButton } from '../common/tvbutton';
import { PLAYBACK_MATCH_STATE, TIMESHIFTING_TYPE_MAP } from '../../../ui/components/controls/playback/const';
import { USER_CLICK, USER_NEXT, USER_PREVIOUS, USER_TIMESHIFTING_BACK_TO_LIVE, USER_TIMESHIFTING_BEGIN } from '../../../types';
import { usePlayerContext } from '../../../ui/hooks';
import { TOGGLE_SHOW_PANNEL_LIVE_OPTION } from '../../../store/types';
import { NO_LABEL } from '../../../core/tracks/types';
import './style.css';
import { EMPTY_ACTIVE_NAME, INFOS_NAME, PLAYLIST_NAME, RECO_NAME, TRACKS_NAME, ZAPPING_NAME } from '../../../ui/components/wrappers/Zap/constants';
import { BTN_ACTIVE_SCALE, BTN_SIZE } from '../common/tvbutton/const';
import { getAdjacentPlaylistMedias } from '../../../utils';
import { mapTitleMetadata } from '../../../ui/utils/tools';

const isActive = ({ currentTab, show }, thisTab) => (show && currentTab === thisTab);

const setFocusFor = (panel, option) => {
  setTimeout(() => (
    !isActive(option, panel)
    && document.getElementsByClassName('ftv-magneto--selectable')[0]?.focus()
  ), 200);
};

const togglePanelFor = (panelName, dispatch, option) => dispatch({
  type: TOGGLE_SHOW_PANNEL_LIVE_OPTION,
  payload: { show: !option.show, currentTab: !option.show ? panelName : EMPTY_ACTIVE_NAME }
});

function TVCommandsWrapper({
  playButton, isLive, isAd, isDVR, isStartOverEnabled, next, previous, showCountdown,
  timeshifting, isAbleToStartOverTS, startOverTimeshifting, audiosAvailable, subtitlesAvailable, panelLiveOption, showNextPlaylistBtn,
  showPlaylist, showZapping, id, playlist, playlistIdsAsString, playlistEnabled, description, title, preTitle, additionalTitle, showReco
}) {
  const player = usePlayerContext();

  const translateY = useCallback(() => ((BTN_SIZE * BTN_ACTIVE_SCALE) - BTN_SIZE) / 2, []);

  const style = {
    float: 'left',
    '-webkit-transform': `translateY(${translateY()}px)`, // center with others
    transform: `translateY(${translateY()}px)` // center with others
  };

  const { previousMedia, nextMedia, previousIndex, nextIndex } = useMemo(
    () => getAdjacentPlaylistMedias(playlist, id),
    [id, playlistIdsAsString]
  );

  const commandsBtnClassName = 'ftv-btn-commands';
  const isTracksActive = isActive(panelLiveOption, TRACKS_NAME);
  const isPlaylistActive = isActive(panelLiveOption, PLAYLIST_NAME);
  const isZappingActive = isActive(panelLiveOption, ZAPPING_NAME);
  const isInfosActive = isActive(panelLiveOption, INFOS_NAME);
  const isRecoActive = isActive(panelLiveOption, RECO_NAME);
  return (
    <div
      className="ftv-magnetoscope-commands-wrapper"
    >
      <TVButton /* START OVER BUTTON */
        hidden={
          !(isLive && (timeshifting ? isAbleToStartOverTS : isStartOverEnabled))
          || ((isAd || showCountdown) && !startOverTimeshifting) || (isDVR && !timeshifting)
        }
        onClick={() => {
          player.startOver(TIMESHIFTING_TYPE_MAP[timeshifting]);
          player.userEvents$.next({
            action: USER_TIMESHIFTING_BEGIN,
            source: USER_CLICK
          });
        }}
        name="tv-back-to-start"
        icon="tv-back-to-start"
        size={BTN_SIZE}
        style={style}
        className={commandsBtnClassName}
      />

      <TVButton /* PREVIOUS BUTTON */
        hidden={isAd || showCountdown || !previous || !previousMedia}
        onClick={() => {
          player.userEvents$.next({
            action: USER_PREVIOUS,
            source: USER_CLICK
          });

          player.load({
            ...previousMedia,
            config: {
              ...previousMedia.config,
              diffusion: { mode: 'tunnel', position: previousIndex + 1, length: playlist.length },
              tracking: {
                pageProvenance: '',
                zoneProvenance: '',
                positionVignette: '',
                playProvenance: 'tunnel_previous'
              },
              ...(previousIndex >= 0
                ? {
                  startTimecode: 0,
                  ...mapTitleMetadata(playlist[previousIndex], { title, preTitle, additionalTitle })
                } : {})
            }
          });
        }}
        name="tv-previous"
        icon="tv-previous"
        size={BTN_SIZE}
        style={style}
        className={commandsBtnClassName}
      />
      {/* PLAY | PAUSE */}
      <TVButton
        name={playButton.toLocaleLowerCase()}
        size={70}
        onClick={() => {
          if (PLAYBACK_MATCH_STATE[playButton].userEvent) {
            player.userEvents$.next({
              action: PLAYBACK_MATCH_STATE[playButton].userEvent,
              source: USER_CLICK
            });
          }
          player[PLAYBACK_MATCH_STATE[playButton].fn]({ userGesture: true });
        }}
        icon={`tv-${playButton.toLocaleLowerCase()}`}
        style={{
          ...style,
          '-webkit-transform': 'unset', // center with others
          transform: 'unset' // center with others
        }}
        className={commandsBtnClassName}
        disableScale
      />

      <TVButton /* BACK TO LIVE BUTTON */
        hidden={(timeshifting ? !startOverTimeshifting : isLive)
          || ((isAd || showCountdown) && !startOverTimeshifting)
          || (!isDVR && !timeshifting)}
        onClick={() => {
          player.backToLive(TIMESHIFTING_TYPE_MAP[timeshifting]);
          player.userEvents$.next({
            action: USER_TIMESHIFTING_BACK_TO_LIVE,
            source: USER_CLICK
          });
        }}
        type="icon"
        name="tv-back-to-live"
        size={BTN_SIZE}
        icon="tv-back-to-live"
        style={style}
        className={commandsBtnClassName}
      />

      <TVButton /* NEXT BUTTON */
        name="tv-next"
        hidden={
          isDVR
          || isAd
          || showCountdown
          || (playlistEnabled ? (!showNextPlaylistBtn || !nextMedia) : !next)
        }
        size={BTN_SIZE}
        onClick={() => {
          player.userEvents$.next({
            action: USER_NEXT,
            source: USER_CLICK
          });
          if (nextMedia) {
            player.load({
              ...nextMedia,
              config: {
                ...nextMedia.config,
                diffusion: { mode: 'tunnel', position: nextIndex + 1, length: playlist.length },
                tracking: {
                  pageProvenance: '',
                  zoneProvenance: '',
                  positionVignette: '',
                  playProvenance: 'tunnel_next'
                },
                ...(nextIndex >= 0 ? { startTimecode: 0 } : {})
              }
            });
          } else {
            player.next('button');
          }
        }}
        icon="tv-next"
        style={style}
        className={commandsBtnClassName}
      />

      <TVButton
        name={TRACKS_NAME}
        size={BTN_SIZE}
        hidden={isAd || !(subtitlesAvailable.length || (audiosAvailable.length > 1))}
        onClick={() => {
          player.panelController.isTrackViewActive$.next(!isTracksActive);
          togglePanelFor(TRACKS_NAME, player.store.dispatch, panelLiveOption);
          setFocusFor(TRACKS_NAME, panelLiveOption);
        }}
        icon="tv-tracks"
        forceActive={isTracksActive}
        style={style}
        className={commandsBtnClassName}
      />

      {/* PLAYLIST */}
      <TVButton
        hidden={isAd || !showPlaylist}
        name={PLAYLIST_NAME}
        size={BTN_SIZE}
        onClick={() => {
          togglePanelFor(PLAYLIST_NAME, player.store.dispatch, panelLiveOption);
          setFocusFor(PLAYLIST_NAME, panelLiveOption);
        }}
        icon="tv-playlist"
        style={style}
        forceActive={isPlaylistActive}
        className={commandsBtnClassName}
      />
      {/* ZAPPING */}
      <TVButton
        hidden={isAd || !showZapping}
        name={ZAPPING_NAME}
        size={BTN_SIZE}
        onClick={() => {
          togglePanelFor(ZAPPING_NAME, player.store.dispatch, panelLiveOption);
          setFocusFor(ZAPPING_NAME, panelLiveOption);
        }}
        icon="tv-zapping"
        style={style}
        forceActive={isZappingActive}
        className={commandsBtnClassName}
      />

      {/* PROGRAM INFOS */}
      <TVButton
        hidden={isAd || isLive || !description || !description.length}
        name={INFOS_NAME}
        size={BTN_SIZE}
        onClick={() => {
          togglePanelFor(INFOS_NAME, player.store.dispatch, panelLiveOption);
          setFocusFor(INFOS_NAME, panelLiveOption);
        }}
        icon="tv-infos"
        style={style}
        forceActive={isInfosActive}
        className={commandsBtnClassName}
      />

      {/* RECOMMENDATIONS */}
      <TVButton
        hidden={isLive || !showReco}
        name={RECO_NAME}
        size={BTN_SIZE}
        onClick={() => {
          togglePanelFor(RECO_NAME, player.store.dispatch, panelLiveOption);
          setFocusFor(RECO_NAME, panelLiveOption);
        }}
        icon="tv-heart"
        style={style}
        forceActive={isRecoActive}
        className={commandsBtnClassName}
      />
    </div>
  );
}

const selector = ({
  ui: { next, panelLiveOption },
  ad: { showCountdown },
  playback: { playButton },
  media: {
    id,
    isLive,
    isAd,
    isDAI,
    isDVR,
    isStartOverEnabled,
    timeshifting: {
      type: timeshifting,
      isAbleToStartOver: isAbleToStartOverTS,
      startOverTimeshifting
    },
    audiosAvailable,
    subtitlesAvailable: subtitles,
    description,
    title,
    preTitle,
    additionalTitle
  },
  zapping: { list, zappingEnabled = list.length > 0 },
  playlist: { playlist, playlistIdsAsString, playlistEnabled = playlist.length > 0 },
  recommendations: { recommendations, recoEnabled = recommendations.length > 0 }
}) => {
  const subtitlesAvailable = [
    ...(subtitles.length
      ? [{
        language: null,
        index: -1,
        label: { label: NO_LABEL, ariaLabel: NO_LABEL }
      }]
      : []),
    ...subtitles
  ];

  return ({
    playButton: (
      (isLive && !timeshifting) && !(isAd && !isDAI) && playButton === 'PAUSE'
        ? 'STOP'
        : playButton
    ),
    id,
    isLive,
    isStartOverEnabled,
    isDVR,
    isAd,
    showCountdown,
    next,
    timeshifting,
    isAbleToStartOverTS,
    startOverTimeshifting,
    subtitlesAvailable,
    audiosAvailable,
    panelLiveOption,
    previous: !isLive && playlistEnabled && playlist[0].src !== id,
    showNextPlaylistBtn: !isLive && playlistEnabled && playlist[playlist.length - 1].src !== id,
    showPlaylist: !isLive && playlistEnabled,
    showReco: !isLive && recoEnabled,
    playlist,
    showZapping: isLive && zappingEnabled,
    playlistIdsAsString,
    playlistEnabled,
    description,
    title,
    preTitle,
    additionalTitle
  });
};

const TVCommands = connect(selector)(TVCommandsWrapper);

export default TVCommands;
