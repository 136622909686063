export default class TTMLParser {
  static parse(isd) {
    const defaultStyle = {
      alignment: {
        horizontal: 'center',
        vertical: 'end'
      },
      position: {
        left: 50,
        top: 100
      }
    };
    if (isd.contents) {
      const tags = TTMLParser.getTags([], isd.contents);

      return { tags, ...defaultStyle };
    }

    return { tags: [], ...defaultStyle };
  }

  static getTags(tags, contents) {
    return [...tags, ...contents.reduce((acc, content) => {
      if (content.kind === 'span') {
        return [...acc, {
          tagName: 'p',
          cssClasses: ['white', 'bg_black'],
          text: content.text.trim()
        }];
      }
      if (!content.contents) {
        return acc;
      }

      return TTMLParser.getTags(tags, content.contents);
    }, [])];
  }
}
