import { h } from 'preact';
import '../../theme/styles/subtitles.css';
import { connect } from '../../hoc';
import { SUBTITLE_CONTAINER_STYLE, SUBTITLE_LINE_STYLE } from './styles';
import { subtitlePositionAndSize } from '../../utils/tools';

function SubtitleContainer({ width, height, tags, active, alignment, position, style, sideMargin }) {
  return active ? (
    <div style={style}>
      <div
        className="ftv-magneto--subtitles"
        style={{
          ...SUBTITLE_CONTAINER_STYLE,
          ...subtitlePositionAndSize({ width, height, alignment, position }, sideMargin)
        }}
      >
        {tags.map(({ tagName, text, cssClasses }) => {
          const Tag = tagName;
          return <Tag className={cssClasses.join(' ')} style={SUBTITLE_LINE_STYLE}>{text}</Tag>;
        })}
      </div>
    </div>
  ) : null;
}

const selector = ({
  ui: { width, height },
  subtitles: { tags, active, alignment, position }
}) => ({ width, height, tags, active, alignment, position });
export default connect(selector)(SubtitleContainer);
