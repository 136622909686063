import { of, Subject, forkJoin, ReplaySubject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { playerInfo, requestAPI, systemInfo, deepMerge, getApiUrlByPlatform, get } from '../../utils';
import { formatGatewayError, formatGeolocError, formatTokenError, formatDRMError, formatReachError } from '../../error/formats';
import { geoloc } from '../../configuration';
import { CAPABILITY_DRM, ON_TOKEN_READY, PLATFORM_FORCED_MAP, TV_PLATFORMS } from './types';
import { UNKNOWN } from '../../utils/types';
import { BROADCASTING_TYPE_AUDIO } from '../../types';

export default class Media {
  /* Store the current media before isUrlReachable check.
     If isUrlReachable throw, we still have data within reach */
  static pendingMedia$ = new ReplaySubject(1);

  constructor(video, playerEvents$, videoUnlocker) {
    this.videoUnlocker = videoUnlocker;
    this.video = video;

    this.events$ = new Subject();
    this.events$.subscribe(playerEvents$);
  }

  static addSystemInfoQueryString({
    sysInfo,
    sysInfoToSend = ['browser', 'browserVersion', 'os', 'osVersion', 'isMobile']
  }) {
    const camelCaseToUnderscore = (s) => s.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();

    /* iPad must have isMobile set to true */
    const isMobile = sysInfo.isMobile || (!sysInfo.isMobile && sysInfo.isTablet);
    const format = (k, v) => (k === 'isMobile' ? `device_type=${isMobile ? 'mobile' : 'desktop'}` : `${camelCaseToUnderscore(k)}=${v}`);

    return Object.entries(sysInfo)
      .filter(([key]) => sysInfoToSend.includes(key))
      .reduce((qs, [key, value]) => `${qs}&${format(key, value)}`, '');
  }

  static addPlatformSpecificsSystemInfo(platform, forcedQueriesPerPlatforms) {
    if (!(platform in forcedQueriesPerPlatforms)) return '';

    const forcedQueries = forcedQueriesPerPlatforms[platform].force;

    const queryString = Object.keys(forcedQueries)
      .map((queryKey) => `${queryKey}=${forcedQueries[queryKey]}`)
      .join('&');

    return `&${queryString}`;
  }

  static addDomainQueryString(originUrl, { hostname } = {}) {
    let domain = hostname;
    /* If an origin url is given, we want to track as if we were on the given product */
    if (originUrl) domain = new URL(originUrl).hostname;
    return `&domain=${domain || UNKNOWN}`;
  }

  static searchSpecificPlatformInUserAgent(navigator, platformsIdentifersInUAs = {}) {
    const matchedIdentifier = Object.keys(platformsIdentifersInUAs).find((identifer) => navigator.userAgent.includes(identifer));
    return platformsIdentifersInUAs[matchedIdentifier] || '';
  }

  static addTvQueryString(platform, env = {}) {
    let platformKey = 'default';
    if (TV_PLATFORMS.includes(platform)) {
      platformKey = platform;
    }

    return PLATFORM_FORCED_MAP[platformKey].transmit.reduce(
      (acc, key) => (env[key]
        ? `${acc}&${key}=${env[key]}`
        : acc
      ),
      ''
    );
  }

  static constructGatewayUrl(gatewayUrl, {
    src, country, width, height, mode, gmt, originUrl, videoProductId, embedCode, platform: platformOption, env, capabilities
  }) {
    const { version } = playerInfo();
    const stdParams = `country_code=${country}&w=${width}&h=${height}&screen_w=${window.screen.width}&screen_h=${window.screen.height}&player_version=${version}`;
    const diffusionMode = mode ? `&diffusion_mode=${mode}` : '';
    const domain = Media.addDomainQueryString(originUrl, window.location);

    const platform = platformOption || Media.searchSpecificPlatformInUserAgent(navigator, { SOPOpenBrowser: 'orangeott' });
    const sysInfo = Media.addSystemInfoQueryString({
      sysInfo: systemInfo,
      ...TV_PLATFORMS.includes(platform) && { sysInfoToSend: PLATFORM_FORCED_MAP[platform].sysInfoToSend }
    });

    const platformSysInfo = Media.addPlatformSpecificsSystemInfo(platform, PLATFORM_FORCED_MAP);

    const gmtQS = gmt ? `&gmt=${encodeURIComponent(gmt.replace('GMT', ''))}` : '';
    const embed = embedCode ? '&embed=true' : '';
    const videoProductIdQS = videoProductId ? `&video_product_id=${videoProductId}` : '';
    const capabilitiesQS = capabilities ? `&capabilities=${capabilities}` : '';

    const tvOtt = Media.addTvQueryString(platform, env);

    return `${gatewayUrl}${src}?${stdParams}${domain}${sysInfo}${platformSysInfo}${diffusionMode}${gmtQS}${embed}${videoProductIdQS}${tvOtt}${capabilitiesQS}`;
  }

  static isLive(media) {
    return Boolean(media.video.is_live
      && typeof media.video.is_live !== 'undefined'
      && media.video.is_live);
  }

  static handleGeoLoc(url) {
    return requestAPI({
      url,
      selector: (response) => ({ country: response?.json?.reponse?.geo_info?.country_code || '', ip: response?.json?.reponse?.geo_info?.ip || '' }),
      formatError: formatGeolocError
    });
  }

  static handleGMT() {
    const offset = new Date().getTimezoneOffset();
    const sign = offset > 0 ? '-' : '+';
    return sign + `${parseInt(Math.abs(offset) / 60, 10)}`.padStart(2, '0') + `${Math.abs(offset) % 60}`.padStart(2, '0');
  }

  static handleWorkflow(workflows, handler, playerConfig, tokenPath, events$) {
    return switchMap((media) => (workflows.includes(media?.video?.workflow) && media?.video?.token
      ? handler(media, get(media, tokenPath), events$, playerConfig)
      : of(media)));
  }

  static handleToken(media, token, events$) {
    const encodedUrl = encodeURIComponent(media.video.url);
    return requestAPI({
      url: `${token}${token.match(/\?[\w0-9]+/) ? '&' : '?'}url=${encodedUrl}`,
      selector: ({ json: { url: tokenizedUrl } }) => ({ ...media, video: { ...media.video, url: tokenizedUrl } }),
      formatError: formatTokenError
    }).pipe(
      Media.fireOnTokenOp(events$)
    );
  }

  static handleDrm(media, tokenUrl) {
    const { laUrl = null, certificateUrl = null, drm_type: drmType = null, license_type: licenseType = null } = media.video.drm;
    return requestAPI({
      url: tokenUrl,
      method: 'POST',
      body: {
        id: media.meta.id,
        drm_type: drmType,
        license_type: licenseType
      },
      headers: {
        'Content-Type': 'application/json'
      },
      selector: ({ json: { token } }) => ({
        ...media,
        video: {
          ...media.video,
          drm: {
            token,
            playReadyLaUrl: laUrl,
            widevineLaUrl: laUrl,
            fairplayLaUrlLicenses: laUrl,
            fairplayLaUrlCertificates: certificateUrl
          }
        }
      }),
      formatError: formatDRMError
    });
  }

  static handleDai(media, token, _, playerConfig) {
    if (!token) return of(media);
    const fw3pUID = [];
    if (playerConfig?.publicId && playerConfig?.consent?.freewheel) {
      if (playerConfig?.consent?.euid) fw3pUID.push(`euid:${playerConfig.consent.euid}`);
      if (playerConfig?.consent?.idl) fw3pUID.push(`idl:${playerConfig.consent.idl}`);
    }
    return requestAPI({
      url: `${token}?User-Agent=ios`,
      method: 'POST',
      body: {
        reportingMode: 'server',
        adsParams: {
          _fw_gdpr: '1',
          _fw_gdpr_consent: (playerConfig?.consent?.ad === null || playerConfig?.consent?.ad === undefined)
            ? ''
            : playerConfig?.consent?.ad.toString(),
          _fw_vcid2: playerConfig?.consent?.adUserId
            ? `${playerConfig?.consent?.adUserId}`
            : ((playerConfig?.consent?.ad && playerConfig?.publicId) || ''),
          _fw_3p_UID: fw3pUID.join(),
          cookiesconsent: Boolean(playerConfig?.consent?.freewheel).toString(),
          support: media.markers.pub.support || ''
        }
      },
      selector: Media.daiSelector(media)
    });
  }

  static daiSelector(media) {
    return ({ json: { manifestUrl, trackingUrl } }) => {
      const awsId = manifestUrl.split('aws.sessionId=')[1];
      const amazonDomain = new URL(media.markers.pub.mediaTailorUrl).origin;
      const mediaUrl = `${media.video.url}?aws.sessionId=${awsId}`;
      const pollingUrl = `${amazonDomain}${trackingUrl}`;
      return {
        ...media,
        isDAI: true,
        video: {
          ...media.video,
          url: mediaUrl
        },
        markers: {
          ...media.markers,
          pub: {
            ...media.markers.pub,
            pollingUrl
          }
        }
      };
    };
  }

  static isUrlReachable() {
    return switchMap((media) => requestAPI({
      url: media.video.url,
      method: 'GET',
      responseType: 'access',
      formatError: formatReachError,
      selector: () => media
    }));
  }

  static sanitizeMedia({ media, playerConfig, config, country, videoUnlocker }) {
    const mergeConfig = deepMerge(
      playerConfig,
      config,
      ((config.index >= 1 && !Object.prototype.hasOwnProperty.call(config, 'autostart')) ? { autostart: true } : {})
    );

    const { preload, autostart, diffusion, autostart: originalAutostart } = mergeConfig;
    const isLive = Media.isLive(media);

    const mergedCM = {
      timecode: media.video?.coming_next?.timecode || mergeConfig?.comingNext?.timecode,
      duration: media.video?.coming_next?.duration || mergeConfig?.comingNext?.duration,
      time_before_dismiss: media.video?.coming_next?.time_before_dismiss || mergeConfig?.comingNext?.timeBeforeDismiss
    };

    const mergedMedia = deepMerge(media, { video: { coming_next: mergedCM } });

    return (autostart
      ? videoUnlocker.getAutoplay(playerConfig)
      : of(false)
    ).pipe(map((autostartEnabled) => {
      /**
       * Autostart check :
       * - check autostart video config - if absent, it'll default to autostart: true
       * - check if autoplay is permitted on the current browser
       * - autoplay is usually blocked until the first user gesture, if we're in a "playlist"
       *   (france.tv tunnel) we can allow autostart on new video loads (video tags will be unlocked)
       *   by checking media.config.index
       */
      const shouldAutostart = mergeConfig.autostart && (autostartEnabled || config.index >= 1);

      return ({
        ...mergedMedia,
        isLive,
        userCountryCode: country,
        config: {
          ...mergeConfig,
          ...(diffusion ? ({
            diffusion: {
              ...diffusion,
              length: (diffusion.length ? diffusion.length : null) /* normalize tunnel length */
            }
          }) : {}),
          autostart: shouldAutostart,
          originalAutostart,
          /* We preload if autostart true, or on desktop if preload true */
          preload: (!media.video.timeshiftable && autostart) || (!systemInfo.isMobile && !isLive && preload)
        }
      });
    }));
  }

  static fireOnTokenOp(events$) {
    return tap((media) => events$.next({ name: ON_TOKEN_READY, payload: media.video.url }));
  }

  static createMediaStream(video, events$, videoUnlocker) {
    const {
      src, config, playerConfig, containerWidth, containerHeight
    } = video;
    const {
      diffusion: { mode } = {},
      originUrl,
      videoProductId,
      embedCode,
      webservices,
      platform,
      env,
      broadcastingType,
      startTimecode
    } = { ...playerConfig, ...config };
    const gmt = Media.handleGMT();

    return Media.handleGeoLoc(geoloc.url).pipe(
      switchMap(({ country, ip }) => {
        const apiStream = requestAPI({
          url: Media.constructGatewayUrl(getApiUrlByPlatform(Media.getServiceApi(broadcastingType), webservices, platform), {
            src: encodeURIComponent(src),
            country,
            width: containerWidth,
            height: containerHeight,
            mode: `${mode}${(startTimecode > 0 && '_resume') || ''}`,
            gmt,
            originUrl,
            videoProductId,
            embedCode,
            platform,
            env,
            capabilities: CAPABILITY_DRM
          }),
          formatError: formatGatewayError.bind(null, ip),
          selector: ({ json }) => json
        }).pipe(
          Media.mapMediaContent(broadcastingType),
          Media.toggleTokenWorkflow(events$, playerConfig)
        );
        return forkJoin([of(country), apiStream]);
      }),
      map(([country, media]) => ({ country, media })),
      switchMap(({ country, media }) => Media.sanitizeMedia({
        media,
        playerConfig,
        config,
        country,
        videoUnlocker
      })),
      tap((value) => Media.pendingMedia$.next(value)),
      Media.isUrlReachable()
    );
  }

  static legacyTokenPipelineWorkflows(media, events$, playerConfig) {
    return of(media).pipe(
      Media.handleWorkflow(['dai'], Media.handleDai, playerConfig, 'markers.pub.mediaTailorUrl', events$),
      Media.handleWorkflow(['token-akamai', 'dai'], Media.handleToken, playerConfig, 'video.token', events$),
      Media.handleWorkflow(['token-drm'], Media.handleDrm, playerConfig, 'video.token', events$)
    );
  }

  static toggleTokenWorkflow(events$, playerConfig) {
    return switchMap((media) => (media?.video?.workflow === null && media?.video?.token
      ? Media.handleWorkflowToken(media, media.video.token, events$, playerConfig)
      : Media.legacyTokenPipelineWorkflows(media, events$, playerConfig)
    ));
  }

  static handleWorkflowToken = (media, tokens, events$, playerConfig) => {
    const HANDLERS_MAPPING = {
      orange: { handler: Media.handleToken, priorityMerge: 1 },
      drm: { handler: Media.handleDrm, priorityMerge: 2 },
      akamai: { handler: Media.handleToken, priorityMerge: 1 },
      dai: { handler: Media.handleDai, priorityMerge: 0 }
    };

    const filteredHandlers = Object.entries(tokens)
      .map(([key, token]) => ({ handler: HANDLERS_MAPPING[key]?.handler, token, key, priority: HANDLERS_MAPPING[key]?.priorityMerge }))
      .sort((a, b) => a.priority - b.priority);

    return of(media).pipe(...filteredHandlers.map(({
      handler, token
    }) => switchMap((m) => ((handler && token) ? handler(m, token, events$, playerConfig) : of(m)))));
  };

  get stream$() {
    return Media.createMediaStream(this.video, this.events$, this.videoUnlocker);
  }

  static mapMediaContent = (broadcastingType) => map((media) => {
    const video = media[broadcastingType];
    // eslint-disable-next-line no-param-reassign
    delete media[broadcastingType];
    return { ...media, video };
  });

  static getServiceApi = (broadcastingType) => (
    broadcastingType === BROADCASTING_TYPE_AUDIO
      ? 'audio'
      : 'gateway'
  );
}
