import {
  OVERLAY_COLOR_OPAQUE,
  PRIMARY_TEXT_COLOR
} from '../../../ui/theme/colors';
import { TRACKS_LAYER, UNDER_LAYER } from '../../../ui/theme/constants';

export const TV_MAIN_WRAPPER = {
  position: 'relative',
  top: 0,
  bottom: 0,
  width: '100%',
  height: '100%',
  fontFamily: 'Brown'
};

export const TV_MAIN_CONTENT = {
  position: 'absolute',
  left: 80,
  right: 80,
  top: 40,
  bottom: 40
};

export const TV_SIDE_OVERLAY_WRAPPER = {
  height: '100%',
  width: '40%',
  position: 'absolute',
  top: 0,
  right: 0,
  backgroundColor: OVERLAY_COLOR_OPAQUE,
  zIndex: TRACKS_LAYER
};

export const TV_SIDE_OVERLAY_WRAPPER_TRACKS = {
  ...TV_SIDE_OVERLAY_WRAPPER,
  padding: 40
};

export const SUBTITLE_CONTAINER_STYLE = {
  zIndex: UNDER_LAYER,
  fontFamily: 'Brown',
  fontWeight: 400,
  textAlign: 'center',
  color: PRIMARY_TEXT_COLOR,
  position: 'absolute',
  pointerEvents: 'none'
};

export const SUBTITLE_LINE_STYLE = {
  margin: 0,
  padding: '1px 3px',
  textWrap: 'nowrap'
};
