import slugify from './slugify';

export function computeVideoTitle(videoTitle, subTitle, videoPreTitle, videoAdditionalTitle) {
  if (slugify(videoTitle) === slugify(videoAdditionalTitle)) {
    return ({
      computedTitle: videoPreTitle ? `${videoPreTitle} : ${videoTitle}` : videoTitle || '',
      computedSubtitle: ''
    });
  }
  return ({
    computedTitle: videoTitle || subTitle || '',
    computedSubtitle: (videoPreTitle && videoAdditionalTitle)
      ? `${videoPreTitle} : ${videoAdditionalTitle}`
      : (videoPreTitle || videoAdditionalTitle || '')
  });
}
