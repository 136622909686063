import '@maxwell/maxwell';
import { map, forkJoin, switchMap, withLatestFrom } from 'rxjs';
import Config from '../../configuration';
import {
  MAXWELL_AD_MODE_ACTIVATED,
  MAXWELL_AD_MODE_DEACTIVATED,
  MAXWELL_PLAYER_EVENT,
  MAXWELL_TRACKER_NOT_OK,
  MAXWELL_TRACKER_OK,
  MAXWELL_TRACKER_UNKNOWN
} from './types';
import FreewheelController from '../../ad/freewheel';
import { isUrlBlocked } from '../../utils';
import { UNKNOWN } from '../../utils/types';

export default class MaxwellController {
  constructor({ medias$, playerConfig$, adStatus$ }) {
    /**
     *
     * Branch all others trackers
     */
    const TRACKER_URLS = {
      dc1: `https://${Config.eStat.pingUrl}`,
      dc2: 'https://tag.aticdn.net/piano-analytics.js'
    };

    MaxwellController.createMaxwellStream({
      medias$,
      trackerUrls: TRACKER_URLS,
      playerConfig$,
      adStatus$
    }).subscribe(MaxwellController.emit);
  }

  static checkAllTrackerStream(trackerUrls) {
    return forkJoin(Object.keys(trackerUrls).reduce(
      (trackers, key) => ({ ...trackers, [key]: MaxwellController.checkTrackerStream(trackerUrls[key]) }),
      {}
    ));
  }

  static checkTrackerStream(url) {
    return isUrlBlocked(url).pipe(
      map(MaxwellController.mapValue)
    );
  }

  static mapValue(value) {
    switch (value) {
      case true:
        return MAXWELL_TRACKER_NOT_OK;
      case false:
        return MAXWELL_TRACKER_OK;
      case UNKNOWN:
        return MAXWELL_TRACKER_UNKNOWN;
      default:
        return null;
    }
  }

  static createMaxwellStream({
    medias$,
    trackerUrls,
    playerConfig$,
    adStatus$
  }) {
    return medias$.pipe(
      withLatestFrom(playerConfig$),
      switchMap((data) => adStatus$.pipe(
        map((andData) => [...data, andData])
      )),
      switchMap(([
        {
          markers: {
            maxwell: {
              channel, region, contentStatus, contentType, category, offer, product,
              contentId, broadcastingType, daiStatus, originServer, event
            }
          },
          isLive,
          userCountryCode: country
        },
        { showAd, autostart },
        {
          adBlocked,
          profile,
          contentDuration,
          csid,
          sfid,
          vcid2,
          euid,
          gdprConsent,
          cmpn,
          cookiesConsent,
          adResponse,
          preroll,
          prerollDuration,
          midroll,
          midrollDuration,
          pauseroll
        }
      ]) => MaxwellController.checkAllTrackerStream(trackerUrls).pipe(
        map((dcs) => ({
          offer, product,
          dcs: { ...dcs, dc4: MaxwellController.mapValue(adBlocked) },
          options: {
            contentStatus,
            contentType,
            category,
            channel,
            region,
            contentId,
            broadcastingType,
            daiStatus: MaxwellController.mapValue(daiStatus),
            originServer,
            contentAd: MaxwellController.isPubable({ showAd, isLive }),
            event,
            autoStart: MaxwellController.mapValue(autostart),
            adRequestStatus: MaxwellController.mapValue(adBlocked),
            adResponse: MaxwellController.mapValue(adResponse),
            adProfile: profile,
            contentDuration,
            mediaContext: csid,
            adSfid: sfid,
            adUserSet: vcid2,
            adHashedId: euid,
            adGdprConsent: gdprConsent,
            adTargeted: MaxwellController.mapValue(cmpn),
            adCookiesConsent: cookiesConsent,
            country,
            adPreCount: preroll,
            adPreDuration: prerollDuration,
            adMidCount: midroll,
            adMidDuration: midrollDuration,
            adPauseCount: pauseroll
          }
        }))
      ))
    );
  }

  static emit({ offer, product, dcs, options }) {
    window.Maxwell.initialize({
      offer,
      product,
      config: {
        baseUrl: Config.maxwell.url
      }
    });
    window.Maxwell.stat({ name: MAXWELL_PLAYER_EVENT, customOptions: dcs, options });
  }

  static isPubable({ showAd, isLive }) {
    return showAd && !(FreewheelController.getCappingId() && isLive)
      ? MAXWELL_AD_MODE_ACTIVATED
      : MAXWELL_AD_MODE_DEACTIVATED;
  }
}
