import {
  delay, distinctUntilChanged, filter, fromEvent,
  map, merge, Subject, switchMap, withLatestFrom
} from 'rxjs';
import { BACK_BTN_SHORTCUTS } from '../shortcut/types';
import { USER_CLOSED_MENU, USER_KEYBOARD, USER_PANEL_CLOSED, USER_PANEL_OPENED } from '../../types';
import { getLastPanelUserEventStream, isTagUnique } from '../../utils';
import { Disposable } from '..';
import { TRANSITION_DURATION } from '../../ui/theme/colors';

const WAIT_DOM_RE_RENDERED_TIME = 50;

export class PanelController extends Disposable {
  constructor({
    userEvents$,
    recommendationController: { shouldDisplay$, recommendations$ },
    config
  }) {
    super();
    this.closePanel$ = new Subject();
    this.isTrackViewActive$ = new Subject();
    this.nextFocusedBtn$ = new Subject();
    this.keydown$ = new Subject();

    const backMapping = !isTagUnique(config) ? [27] : BACK_BTN_SHORTCUTS;
    const focusedPanel$ = new Subject();

    PanelController.keyDown().subscribe(this.keydown$);

    PanelController.createCloseKeyStream({ focusedPanel$, keydown$: this.keydown$, backMapping })
      .subscribe(this.closePanel$);

    PanelController.createFocusedPanel(this.keydown$)
      .subscribe(focusedPanel$);

    PanelController.createIsTrackViewActive(this.keydown$)
      .subscribe(this.isTrackViewActive$);

    PanelController.createFocusElementStrean({ shouldDisplay$, recommendations$, userEvents$, isTV: isTagUnique(config) })
      .subscribe(this.nextFocusedBtn$);
  }

  static keyDown() {
    return fromEvent(document, 'keydown');
  }

  static createCloseKeyStream({ focusedPanel$, keydown$, backMapping }) {
    return merge(
      focusedPanel$.pipe(filter((isPanelViewFocused) => !isPanelViewFocused)),
      focusedPanel$.pipe(
        withLatestFrom(keydown$),
        filter(([isPanelViewFocused, { keyCode, keyIdentifier, key: eventKey }]) => (
          isPanelViewFocused && (
            backMapping.includes(keyIdentifier)
            || backMapping.includes(eventKey)
            || backMapping.includes(keyCode)
          ))),
        map(() => true)
      )
    );
  }

  static createFocusedPanel(keydown$) {
    return keydown$.pipe(
      delay(WAIT_DOM_RE_RENDERED_TIME), // wait for dom to be rendered
      map(() => [
        document.getElementsByName('close-panel')[0]?.contains(document.activeElement),
        document.getElementsByName('btn-close-panel-live-options')[0]?.contains(document.activeElement),
        [...document.querySelectorAll('.ftv-magneto--selectable')].some((e) => e?.contains(document.activeElement)),
        document.getElementsByName('tracks-view')[0]?.contains(document.activeElement),
        document.getElementsByName('close audio et sous-titres')[0]?.contains(document.activeElement),
        document.getElementsByName('program-list-view')[0]?.contains(document.activeElement),
        document.getElementsByName('infos-view')[0]?.contains(document.activeElement)
      ].some((active) => active))
    );
  }

  static createIsTrackViewActive(keydown$) {
    const isActive$ = keydown$.pipe(
      delay(WAIT_DOM_RE_RENDERED_TIME),
      map(() => (
        document.getElementsByName('close audio et sous-titres')[0]?.contains(document.activeElement)
        || document.getElementsByName('tracks-view')[0]?.contains(document.activeElement)
      )),
      map((isActive) => !!isActive),
      distinctUntilChanged()
    );

    const isActiveTrue$ = isActive$.pipe(filter(Boolean));
    const isActiveFalse$ = isActive$.pipe(filter((e) => !e));
    return merge(
      isActiveTrue$,
      isActiveTrue$.pipe(switchMap(() => isActiveFalse$))
    );
  }

  static createFocusElementStrean({ shouldDisplay$, recommendations$, userEvents$, isTV }) {
    const lastClosedPanel$ = getLastPanelUserEventStream(userEvents$).pipe(
      filter(({ action, source }) => action === USER_PANEL_CLOSED && source === USER_KEYBOARD),
      map(({ value: { name } }) => name),
      delay(isTV ? 0 : TRANSITION_DURATION + 2)
    );
    const lastOpenPanel$ = getLastPanelUserEventStream(userEvents$).pipe(
      filter(({ action, source }) => action === USER_PANEL_OPENED && source === USER_KEYBOARD),
      map(() => 'btn-close-panel-live-options'),
      delay(isTV ? 0 : TRANSITION_DURATION + 2)
    );
    const tracksMenu$ = userEvents$.pipe(
      filter(({ action }) => action === USER_CLOSED_MENU),
      map(() => 'btn-tracks')
    );
    const recoTV$ = shouldDisplay$.pipe(
      filter(({ shouldDisplay }) => shouldDisplay),
      delay(300),
      withLatestFrom(recommendations$),
      map(([, recommendations]) => recommendations[0]?.src)
    );

    return merge(
      lastOpenPanel$,
      recoTV$,
      lastClosedPanel$,
      tracksMenu$
    );
  }
}
