/* eslint-disable class-methods-use-this */
import { filter, map, NEVER } from 'rxjs';
import { RecommendationController } from '.';
import { CAUSE_NO_RECO, CAUSE_USER_CLOSED } from './types';
import { takeUntilWithLast } from '../../utils/rx-utils';
import { BLOCK_RECOMMENDATIONS } from '../../types';

export class RecommendationTVController extends RecommendationController {
  createDisplayRecommendationsWithCauseStream(isFlowEnabled$, { userEvents$ }) {
    return isFlowEnabled$.pipe(
      map((isFlowEnabled) => (!isFlowEnabled
        ? { isDisplayable: false, cause: CAUSE_NO_RECO }
        : { isDisplayable: true })),
      takeUntilWithLast(
        userEvents$.pipe(filter((e) => e.action === BLOCK_RECOMMENDATIONS)),
        { isDisplayable: false, cause: CAUSE_USER_CLOSED }
      )
    );
  }

  endStream() {
    return NEVER;
  }
}
