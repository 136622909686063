/* eslint-disable react/no-unknown-property */
import { h } from 'preact';
import SVGWrapper from '../../SVGWrapper';

function TvHeartIcon({ height = 60, width = 60 }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
    >
      <mask id="mask0_9234_39773" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="5" y="6" width="22" height="20">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.3716 10.0793C16.173 10.3 15.827 10.3 15.6284 10.0793L14.8851 9.25345C13.4817 7.69421 11.7778 7.49999 11 7.5C10.346 7.50001 9.1823 7.80535 8.20112 8.60991C7.27933 9.36578 6.5 10.5736 6.5 12.5C6.5 13.268 6.7764 14.1915 7.35766 15.2534C7.93368 16.3057 8.76091 17.4062 9.74037 18.5016C11.6893 20.6811 14.1195 22.7063 16.0001 24.1317C17.8813 22.7067 20.3114 20.6842 22.2602 18.5073C23.2396 17.4132 24.0667 16.314 24.6426 15.2628C25.2237 14.2021 25.5 13.2793 25.5 12.5115C25.5 10.5851 24.7207 9.37724 23.7989 8.62137C22.8177 7.8168 21.654 7.51147 21 7.51147C20.2147 7.51147 18.5149 7.69793 17.1149 9.25345L16.3716 10.0793ZM14.8941 7.25465C13.3744 6.16065 11.8016 5.99999 11 6C9 6.00002 5 7.7 5 12.5C5 16.9433 11.2838 22.4434 15.1262 25.3515C15.6448 25.744 16.3552 25.744 16.8738 25.3517C20.7163 22.4449 27 16.9547 27 12.5115C27 7.71147 23 6.01147 21 6.01147C20.1972 6.01147 18.6211 6.16519 17.0995 7.26047C16.7256 7.52958 16.355 7.85553 16 8.25C15.6429 7.85325 15.2701 7.52537 14.8941 7.25465Z" fill="black" />
      </mask>
      <g mask="url(#mask0_9234_39773)">
        <rect width="32" height="32" fill="black" />
        <rect width="32" height="32" fill="white" />
      </g>
    </SVGWrapper>
  );
}

export default TvHeartIcon;
