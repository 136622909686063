import { h } from 'preact';

function Centrer({ children }) {
  const containerStyle = {
    position: 'relative',
    width: '100%'
  };

  const childStyle = {
    textAlign: 'center'
  };

  return (
    <div style={containerStyle}>
      <div style={childStyle}>{children}</div>
    </div>
  );
}

export default Centrer;
