import { Subject, interval, NEVER } from 'rxjs';
import { switchMap, catchError, shareReplay, combineLatest, take, startWith, map, filter } from 'rxjs/operators';
import Media from './Media';
import { requestAPI } from '../../utils';
import { viewers } from '../../configuration';
import { MEDIA_CHANGED } from '../../store/types';
import { USER_REQUEST_MEDIA_LOAD } from '../../types';
import error from '../../error';
import { USER_MEDIA_ACCESS_ERROR } from '../../error/definitions';
import { buildDefaultTitleMetadata } from '../../utils/media';

export default class MediaController {
  constructor(player) {
    const { currentVideo$, config$, domController: { dimensions$ }, events$, videoUnlocker } = player;

    this.errors$ = new Subject();

    this.medias$ = MediaController.createMediaStream({
      currentVideo$,
      config$,
      dimensions$,
      errors$: this.errors$,
      events$,
      videoUnlocker,
      player
    });

    this.confirmedAutostart$ = MediaController.createConfirmedShouldAutoStartStream(this.medias$);
    this.concurrentViewers$ = MediaController.createViewerStream(this.medias$);

    MediaController.handleUserMediaLoad(player);
  }

  static createConfirmedShouldAutoStartStream(media$) {
    return media$.pipe(map(({ config: { autostart } }) => autostart));
  }

  static createMediaStream({
    currentVideo$,
    config$,
    dimensions$,
    errors$,
    events$,
    videoUnlocker,
    player
  }) {
    return currentVideo$
      .pipe(
        combineLatest(
          config$,
          dimensions$.pipe(take(1)), /* we don't want a new media object on each resize */
          (options, playerConfig, { containerWidth, containerHeight }) => ({
            ...options,
            playerConfig,
            containerWidth,
            containerHeight
          })
        ),
        switchMap((media) => {
          if (!MediaController.userCanLoad(media)) {
            events$.next({ name: 'loginRequired', payload: {
              src: media.src, videoProductId: media.playerConfig?.videoProductId
            } });
            errors$.next({ error: error(USER_MEDIA_ACCESS_ERROR) });
            return NEVER;
          }
          return MediaController.createMedia(media, events$, videoUnlocker);
        }),
        catchError((catchedError, source$) => {
          MediaController.dispatchPendingMedia(player); // we need a minimum set of media info in the store for npawn monitoring
          errors$.next({ error: catchedError, source$ });

          return MediaController.createMediaStream({
            currentVideo$,
            config$,
            dimensions$,
            errors$,
            events$,
            videoUnlocker,
            player
          });
        }),
        shareReplay(1)
      );
  }

  static async dispatchPendingMedia(player) {
    Media.pendingMedia$.pipe(take(1)).subscribe((media) => {
      player.dispatch({
        type: MEDIA_CHANGED,
        payload: {
          // options passed here match the one used in npaw/adapter.js
          isLive: media.isLive,
          ...buildDefaultTitleMetadata(media.config, media.meta),
          markers: media.markers,
          resource: media.video.url,
          comingNext: {},
          timeshifting: {},
          skipIntro: {},
          skipRecap: {}
        }
      });
    });
  }

  static createMedia(options, events$, videoUnlocker) {
    return new Media(options, events$, videoUnlocker).stream$;
  }

  /**
   * Create Live Viewer Stream
   * Pull numberViewer every x interval
   */
  static createViewerStream(medias$) {
    return medias$.pipe(switchMap(({ isLive, meta: { id }, config: { showViewers, minViewers } }) => ((isLive && showViewers)
      ? interval(viewers.pullInterval).pipe(
        startWith(0),
        switchMap(() => requestAPI({ url: `${viewers.url}${id}` })),
        map(({ json: { concurrent: { value: numberViewers } } }) => ({ minViewers, numberViewers })),
        filter(({ numberViewers }) => Boolean(numberViewers))
      )
      : NEVER
    )));
  }

  static handleUserMediaLoad(player) {
    player.userEvents$
      .pipe(filter(({ action }) => action === USER_REQUEST_MEDIA_LOAD))
      .subscribe(({ value }) => player.load(value));
  }

  static userCanLoad({ playerConfig, config }) {
    const { userLoggedIn, checkLogin, loginRequired } = { ...playerConfig, ...config };
    return checkLogin && loginRequired ? userLoggedIn : true;
  }
}
