/* Main MAGNETO events */
export const NEW_PLAYER = 'new_player';
export const NEW_VIDEO = 'new_video';
export const NEW_CONTENT = 'new_content';
export const VIDEO_START = 'video_start';
export const NEXT = 'next';
export const NATURAL = 'naturel';
export const DISPOSED = 'disposed';

export const COMING_NEXT = 'comingNext';
export const RECOMMDATION_NEXT = 'recommendation';
export const NEED_RECO = 'needReco';
export const NEED_PLAYLIST = 'needPlaylist';
export const ZAPPING_CLICKED = 'zappingClicked';
export const ZAPPING_OPENED = 'zappingOpened';
export const ZAPPING_META_OPENED = 'zappingMetaOpened';
export const ZAPPING_SCROLL = 'zappingScroll';
export const ZAPPING_ARROW_SCROLL = 'zappingArrowScroll';
export const ZAPPING_DRAG_SCROLL = 'zappingDragScroll';
export const ZAPPING_WHEEL_SCROLL = 'zappingWheelScroll';
export const ZAPPING_TAB_CLICKED = 'zappingTabClicked';

export const CHAT_DISPLAYED = 'chatDisplayed';
export const CHAT_HIDDEN = 'chatHidden';

export const USER_SETTINGS_UPDATED = 'userSettingsUpdated';

export const SETTINGS_OPENED = 'settingsOpened';
export const SETTINGS_CLOSED = 'settingsClosed';

export const ECO_CLOSED = 'ecoClosed';
export const ECO_OPENED = 'ecoOpened';

export const EMBED_CLOSED = 'embedClosed';
export const EMBED_OPENED = 'embedOpened';

export const TIMESHIFTING_AUTO_CONTROLLER_LOADED = 'timeshiftingAutoControllerLoaded';
export const TIMESHIFTING_CONTROLLER_LOADED = 'timeshiftingControllerLoaded';

/* User interactions with the player */
export const USER_PIP = 'userPiP';
export const USER_FULLSCREEN = 'userFullscreen';
export const USER_TRACK_LIST_OPENED = 'userTrackListOpened';
export const USER_AUDIO_TRACK_CHANGED = 'userAudioTrackChanged';
export const USER_TEXT_TRACK_CHANGED = 'userTextTrackChanged';
export const USER_MUTE = 'userMute';
export const USER_PLAY = 'userPlay';
export const USER_REPLAY = 'userReplay';
export const USER_RECOMMENDATIONS_CLICKED = 'userRecommendationsClicked';
export const USER_BACK_TO_CREDITS = 'userBackToCredits';
export const USER_PAUSE = 'userPause';
export const USER_STOP = 'userStop';
export const USER_SEEK = 'userSeek';
export const USER_SEEK_START = 'userSeekStart';
export const USER_FAST_FORWARD = 'userFastForward';
export const USER_FAST_REWIND = 'userFastRewind';
export const USER_QUALITY_CHANGED = 'userQualityChanged';
export const USER_QUALITY_MENU_OPENED = 'userQualityMenuOpened';
export const USER_PLAYBACKRATE_CHANGE = 'userPlaybackRateChange';
export const USER_REPORT_PROBLEM = 'userReportProblem';
export const USER_REPORT_QUALITY = 'userReportQuality';
export const USER_NEXT = 'userNext';
export const USER_PREVIOUS = 'userPrevious';
export const USER_TIMESHIFTING_BEGIN = 'userTimeshiftingBegin';
export const USER_TIMESHIFTING_BACK_TO_LIVE = 'userTimeshiftingBackToLive';
export const USER_TIMESHIFTING_SEEK = 'userTimeshiftingSeek';
export const USER_VOLUME_CHANGE = 'userVolumeChange';
export const USER_VOLUME_VALUE = 'userVolumeValue';
export const USER_TUNNEL_ACTIVATED = 'userTunnelActivated';
export const USER_OPENED_SETTINGS = 'userOpenedSettings';
export const USER_ECO_TOGGLED = 'userEcoToggled';
export const USER_PINS_CLICKED = 'userPinsClicked';
export const USER_OPENED_MENU = 'userOpenedMenu';
export const USER_CLOSED_MENU = 'userClosedMenu';
export const USER_CLOSED_PAUSEROLL = 'userClosedPauseRoll';
export const USER_PLAY_PAUSEROLL = 'userPlayPauseroll';
export const USER_PANEL_OPENED = 'panelOpened';
export const USER_PANEL_CLOSED = 'panelClosed';
export const USER_PANEL_CLICKED = 'panelClicked';

/* User interaction types */
export const USER_CLICK = 'userClick';
export const USER_KEYBOARD = 'userKeyoard';
export const USER_SHORTCUT = 'userShortcut';
export const USER_OUT_TRACKS = 'userOutTracks';
export const NO_OP = 'noop';
export const BLUR = 'blur';
export const FOCUS_NEXT = 'focus_next';
export const FOCUS_PREVIOUS = 'focus_previous';
export const PLAYER_FLOW = 'playerFlow';

export const BACKGROUND = 'images/background.jpg';
export const BLOCK_RECOMMENDATIONS = 'blockRecommendations';
export const RECOMMENDATIONS_CLICKED = 'recommendationsClicked';

export const BROADCASTING_TYPE_VIDEO = 'video';
export const BROADCASTING_TYPE_AUDIO = 'audio';

export const AUTO = 'auto';
export const USER_REMOTE_ZAPPING_CLICKED = 'userRemoteZappingClicked';
export const USER_REMOTE_HIGHLIGHTS_CLICKED = 'userRemoteHighlightsClicked';
export const USER_REMOTE_CHAT_CLICKED = 'userRemoteChat';
export const USER_REMOTE_PLAYLIST_CLICKED = 'userRemotePlaylistClicked';
export const USER_REMOTE_RECO_CLICKED = 'userRemoteRecoClicked';
export const USER_REMOTE_INFOS_CLICKED = 'userRemoteInfosClicked';
export const USER_PANEL_LIVE_OPTIONS_CLOSE = 'user_panel_live_options_close';
export const USER_PANEL_LIVE_OPTIONS_OPEN = 'user_panel_live_options_open';
export const USER_PANEL_ZAPPING_SWITCH_CLICKED = 'user_panel_zapping_switch_clicked';
export const USER_PANEL_ZAPPING_PROGRAM_CLICKED = 'user_panel_zapping_program_clicked';
export const USER_PANEL_HIGHLIGHTS_PROGRAM_CLICKED = 'user_panel_highlights_program_clicked';
export const USER_PANEL_PLAYLIST_PROGRAM_CLICKED = 'user_panel_playlist_program_clicked';
export const USER_PANEL_RECO_PROGRAM_CLICKED = 'user_panel_reco_program_clicked';

export const USER_REQUEST_MEDIA_LOAD = 'user_request_media_load';

export const SECRET_DEBUG_HASH = 'd3c7811008cfbf322967de9ece804df5e3d35cda';
