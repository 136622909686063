import { OVERLAY_SCREEN_OPAQUE_BACKGROUND } from '../../theme/colors';
import { OVERLAY_LAYER } from '../../theme/constants';

export const AD_INFOS_WRAPPER_STYLE = {
  position: 'absolute',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  zIndex: OVERLAY_LAYER,
  top: 0
};

export const START_SCREEN_CONTAINER_HIDDEN = {
  transition: 'opacity .3s ease-out',
  opacity: 0,
  pointerEvents: 'none'
};

export const START_SCREEN = {
  backgroundColor: OVERLAY_SCREEN_OPAQUE_BACKGROUND,
  position: 'absolute',
  top: 0,
  left: 0,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  filter: 'brightness(80%)'
};

export const START_SCREEN_BUTTON_SIZE = 120;
export const START_SCREEN_BUTTON_SIZE_MINI = 60;

export const OVERLAY_WRAPPER = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
};

export const TITLE_WRAPPER = {
  position: 'relative'
};

export const HEADER_WRAPPER = {
  default: {
    display: 'flex',
    flexDirection: 'row'
  }
};

export const MAIN_TITLE_TRANSITION = {
  transition: 'opacity .2s linear',
  transitionDelay: '.16s',
  opacity: 1
};

export const EXTRA_INFOS_WRAPPER_STYLE = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
};

export const LOGO_WRAPPER = {
  default: {
    marginRight: 15,
    width: 70,
    height: 70,
    marginTop: 22
  },
  'medium+small': {
    marginTop: 18
  },
  large: {
    width: 90,
    height: 90,
    marginTop: 33
  },
  'small+extraSmall': {
    display: 'none'
  }
};
