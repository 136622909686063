import error from './index';
import {
  WEBSERVICE_TIMEOUT_ERROR,
  WEBSERVICE_BAD_RESPONSE_ERROR,
  WEBSERVICE_SERVER_ERROR,
  DEFAULT_GATEWAY_CALL_ERROR,
  GATEWAY_BAD_RESPONSE,
  GATEWAY_TIMEOUT,
  GATEWAY_UNAUTHORIZED,
  GATEWAY_BAD_REQUEST,
  GATEWAY_UNAVALAIBLE,
  ACCESS_BAD_RESPONSE_ERROR,
  ACCESS_TIMEOUT_ERROR,
  ACCESS_DEFAULT_ERROR,
  ACCESS_4XX_ERROR,
  ACCESS_5XX_ERROR,
  ACCESS_GEO_403_ERROR,
  ACCESS_TOKEN_403_ERROR,
  DRM_TOKEN_NOT_RECEIVED_ERROR,
  AKAMAI_GEO_TYPE,
  AKAMAI_STOKEN_TYPE,
  AKAMAI_LTOKEN_TYPE,
  AKAMAI_ERROR_HEADER,
  AKAMAI_MON_ICUID_DEL_HEADER,
  ACCESS_WITH_VPN_NOT_AUTH,
  GATEWAY_BAD_UNAVALAIBLE,
  findErrorByCode,
  ACCESS_PATTERN_403_ERROR
} from './definitions';
import { detectErrorWithPattern } from './orange';

export function formatGatewayError(ip, response) {
  const {
    type,
    json: {
      code,
      message,
      can_retry: canRetry,
      show_code: showCode,
      refresh

    } = {},
    status
  } = response;

  if (type === WEBSERVICE_TIMEOUT_ERROR.type) return error(GATEWAY_TIMEOUT);
  if (type === WEBSERVICE_BAD_RESPONSE_ERROR.type) return error(GATEWAY_BAD_RESPONSE);
  if (type === WEBSERVICE_SERVER_ERROR.type) return error(DEFAULT_GATEWAY_CALL_ERROR);
  if (!status) return error(response);
  if ([404, 410, 422].includes(status)) {
    return error(DEFAULT_GATEWAY_CALL_ERROR, {
      code, message, canRetry, showCode, refresh, ...findErrorByCode(code, message), ip: code === 2009 ? ip : null
    });
  }
  if (status === 400) return error(GATEWAY_BAD_REQUEST);
  if (status > 400 && status < 500) return error(GATEWAY_UNAUTHORIZED);
  if (status >= 500 && status < 502) return error(GATEWAY_UNAVALAIBLE);
  if (status === 502) return error(GATEWAY_BAD_UNAVALAIBLE);

  return error();
}

export function formatAccessError(response) {
  const { type, status } = response;

  if (type === WEBSERVICE_TIMEOUT_ERROR.type) return error(ACCESS_TIMEOUT_ERROR);
  if (type === WEBSERVICE_BAD_RESPONSE_ERROR.type) return error(ACCESS_BAD_RESPONSE_ERROR);
  if (type === WEBSERVICE_SERVER_ERROR.type) return error(ACCESS_DEFAULT_ERROR);
  if (status >= 400 && status < 500) return error(ACCESS_4XX_ERROR);
  if (status >= 500) return error(ACCESS_5XX_ERROR);

  return error(ACCESS_DEFAULT_ERROR);
}

export function formatGeolocError(response = { json: { geo_info: {} } }) {
  const { json: { geo_info: { country_code: country, ip } = {} }, status } = response;

  if (status === 403) return error(ACCESS_GEO_403_ERROR, { message: `${ACCESS_GEO_403_ERROR.message} (${country})<br/>IP: ${ip}` });
  return formatAccessError(response);
}

export function formatTokenError(response) {
  const { status } = response;

  if (status === 403) return error(ACCESS_TOKEN_403_ERROR);
  return formatAccessError(response);
}

export function formatDRMError() {
  return error(DRM_TOKEN_NOT_RECEIVED_ERROR);
}

export function formatReachError(response) {
  const errorType = response.access?.headers?.get(AKAMAI_ERROR_HEADER);

  if (response.status === 403) {
    if (detectErrorWithPattern(response)) return error(ACCESS_PATTERN_403_ERROR);
    switch (errorType) {
      case AKAMAI_GEO_TYPE:
        return error(ACCESS_GEO_403_ERROR);
      case AKAMAI_STOKEN_TYPE:
      case AKAMAI_LTOKEN_TYPE:
        return error(ACCESS_TOKEN_403_ERROR);
      default: {
        const akamaiMonIcuidHeader = response.access?.headers?.get(AKAMAI_MON_ICUID_DEL_HEADER);
        if (akamaiMonIcuidHeader === '903458' || akamaiMonIcuidHeader === '903457') {
          return error(ACCESS_WITH_VPN_NOT_AUTH);
        }
      }
    }
  }
  return formatAccessError(response);
}

export function formatJsonError(message) {
  return ({ json: e }) => error({ ...e, message });
}
