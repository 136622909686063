import { h } from 'preact';
import { usePlayerContext, useStyles } from '../../../../hooks';
import TrackList from './TrackList';
import { TRACKS_WRAPPER_STYLE, TRACKS_WRAPPER_RESPONSIVE_STYLE, TRACKS_WRAPPER_TV_STYLE } from './styles';
import { USER_AUDIO_TRACK_CHANGED, USER_TEXT_TRACK_CHANGED, USER_CLICK } from '../../../../../types';
import { withBreakPoints } from '../../../../hoc';
import Position from '../../../../utils/Position';

/**
 * TODO: Tracks should use Scrollable component just like Settings
 * to handle large lists
 */

function Tracks({
  subtitlesAvailable,
  subtitleSelected,
  audiosAvailable,
  audioSelected,
  mobile,
  isTv,
  showTracks
}) {
  const player = usePlayerContext();
  const setStyles = useStyles();

  return (showTracks
    && (
      <div
        style={setStyles({
          default: TRACKS_WRAPPER_STYLE,
          extraSmall: TRACKS_WRAPPER_RESPONSIVE_STYLE,
          small: { ...TRACKS_WRAPPER_RESPONSIVE_STYLE, marginTop: 20, marginLeft: 24 },
          'largeTv+extraLargeTv': TRACKS_WRAPPER_TV_STYLE
        })}
        onClick={(e) => e.stopPropagation()}
        role="presentation"
        name="tracks-view"
        className="ftv-magneto--tracks"
      >
        <Position>
          <TrackList /* Audios Track list */
            title={`audio${mobile || isTv ? '' : ' (b)'}`}
            ariaLabel="audio raccourci touche b"
            id="audio"
            list={audiosAvailable}
            selected={audioSelected}
            method={({ index, label, language }) => {
              player.userEvents$.next({
                action: USER_AUDIO_TRACK_CHANGED,
                source: USER_CLICK,
                value: { label, language }
              });
              player.setAudioTrack(index);
            }}
          />
          <TrackList /* Subtitles Track list */
            title={`sous-titres${mobile || isTv ? '' : ' (c)'}`}
            ariaLabel="sous-titres raccourci touche c"
            id="sous-titre"
            list={subtitlesAvailable}
            selected={subtitleSelected}
            method={({ index, label, language }) => {
              player.userEvents$.next({
                action: USER_TEXT_TRACK_CHANGED,
                source: USER_CLICK,
                value: { label, language }
              });
              player.setSubtitleTrack(index);
            }}
          />
        </Position>
      </div>
    )
  );
}

export default withBreakPoints(Tracks);
