import { h } from 'preact';
import PlayIcon from './PlayIcon';
import ReplayIcon from './ReplayIcon';
import PauseIcon from './PauseIcon';
import StopIcon from './StopIcon';
import RewindIcon from './RewindIcon';
import ForwardIcon from './ForwardIcon';
import Text10Icon from './Text10Icon';
import BackStartIcon from './BackStartIcon';
import NextIcon from './NextIcon';
import PreviousIcon from './PreviousIcon';
import TracksIcon from './TracksIcon';
import TracksActiveIcon from './TracksActiveIcon';
import PipIcon from './PipIcon';
import FullscreenOnIcon from './FullscreenOn';
import FullscreenOffIcon from './FullscreenOff';
import EmbedIcon from './EmbedIcon';
import EcoIcon from './EcoIcon';
import EcoActiveIcon from './EcoActiveIcon';
import SettingsIcon from './SettingsIcon';
import BackLiveIcon from './BackLiveIcon';
import VolumeMuteIcon from './VolumeMuteIcon';
import VolumeModerateIcon from './VolumeModerateIcon';
import VolumeLowIcon from './VolumeLowIcon';
import VolumeMaxIcon from './VolumeMaxIcon';
import EyeLiveIcon from './EyeLiveIcon';
import CloseIcon from './CloseIcon';
import LeftArrowIcon from './LeftArrowIcon';
import RightArrowIcon from './RightArrowIcon';
import OutIcon from './OutIcon';
import CheckIcon from './CheckIcon';
import ADIcon from './ADIcon';
import SMEIcon from './SMEIcon';
import SDIcon from './SDIcon';
import HDIcon from './HDIcon';
import PauserollResumeIcon from './PauserollResumeIcon';
import { Rewind10Icon } from './Rewind10Icon';
import { Forward10Icon } from './Forward10Icon';
import InfosIcon from './InfosIcon';
import InfosIconActive from './InfosIconActive';

import TvBackStartIcon from './tv/BackStartIcon';
import TvBackStartIconActive from './tv/BackStartIconActive';
import TvPauseIcon from './tv/PauseIcon';
import TvPauseIconActive from './tv/PauseIconActive';
import TvBackLiveIcon from './tv/BackLiveIcon';
import TvBackLiveIconActive from './tv/BackLiveIconActive';
import TvNextIcon from './tv/TvNextIcon';
import TvNextIconActive from './tv/TvNextIconActive';
import TvPlayIcon from './tv/PlayIcon';
import TvPlayIconActive from './tv/PlayIconActive';
import TvTracksIconActive from './tv/TracksIconActive';
import TvStopIcon from './tv/StopIcon';
import TvStopIconActive from './tv/StopIconActive';
import TvReplayIcon from './tv/ReplayIcon';
import TvReplayIconActive from './tv/ReplayIconActive';
import TvTracksIconOutline from './tv/TracksIconOutline';
import { RemoteControl } from './RemoteControl';
import { RemoteControlActive } from './RemoteControlActive';
import { Flag } from './Flag';
import { FlagActive } from './FlagActive';
import { Chat } from './Chat';
import { ChatActive } from './ChatActive';
import PinsIcon from './PinsIcon';
import TvPlaylistIcon from './tv/PlaylistIcon';
import TvPlaylistActiveIcon from './tv/PlaylistActiveIcon';
import TVZappingIcon from './tv/TVZappingIcon';
import TVZappingActiveIcon from './tv/TVZappingActiveIcon';
import PauserollResumeDarkIcon from './PauserollResumeDarkIcon';
import TVPreviousIcon from './tv/TVPreviousIcon';
import TVPreviousActiveIcon from './tv/TVPreviousActiveIcon';
import TvForward4Icon from './tv/Forward4';
import TvForward8Icon from './tv/Forward8';
import TvForward16Icon from './tv/Forward16';
import TvForward32Icon from './tv/Forward32';
import TvForward64Icon from './tv/Forward64';
import TvRewind4Icon from './tv/Rewind4';
import TvRewind8Icon from './tv/Rewind8';
import TvRewind16Icon from './tv/Rewind16';
import TvRewind32Icon from './tv/Rewind32';
import TvRewind64Icon from './tv/Rewind64';
import PlaylistIcon from './PlaylistIcon';
import PlaylistActiveIcon from './PlaylistActiveIcon';
import TvInfoIcon from './tv/InfoIcon';
import TvInfoIconActive from './tv/InfoIconActive';
import HeartIcon from './HeartIcon';
import HeartActiveIcon from './HeartActiveIcon';
import TvHeartActiveIcon from './tv/TvHeartActiveIcon';
import TvHeartIcon from './tv/TvHeartIcon';

export const Icon = ({ name, ...props }) => {
  const ICONS = {
    play: <PlayIcon {...props} />,
    replay: <ReplayIcon {...props} />,
    pause: <PauseIcon {...props} />,
    stop: <StopIcon {...props} />,
    rewind: <RewindIcon {...props} />,
    rewind10: <Rewind10Icon {...props} />,
    forward: <ForwardIcon {...props} />,
    forward10: <Forward10Icon {...props} />,
    text10: <Text10Icon {...props} />,
    'back-to-start': <BackStartIcon {...props} />,
    next: <NextIcon {...props} />,
    tracks: <TracksIcon {...props} />,
    'tracks-active': <TracksActiveIcon {...props} />,
    pip: <PipIcon {...props} />,
    'fullscreen-off': <FullscreenOffIcon {...props} />,
    'fullscreen-on': <FullscreenOnIcon {...props} />,
    embed: <EmbedIcon {...props} />,
    eco: <EcoIcon {...props} />,
    'eco-activated': <EcoActiveIcon {...props} />,
    settings: <SettingsIcon {...props} />,
    'back-to-live': <BackLiveIcon {...props} />,
    'volume-mute': <VolumeMuteIcon {...props} />,
    'volume-low': <VolumeLowIcon {...props} />,
    'volume-moderate': <VolumeModerateIcon {...props} />,
    'volume-max': <VolumeMaxIcon {...props} />,
    'eye-live': <EyeLiveIcon {...props} />,
    close: <CloseIcon {...props} />,
    'right-arrow': <RightArrowIcon {...props} />,
    'left-arrow': <LeftArrowIcon {...props} />,
    out: <OutIcon {...props} />,
    check: <CheckIcon {...props} />,
    ad: <ADIcon {...props} />,
    sme: <SMEIcon {...props} />,
    sd: <SDIcon {...props} />,
    hd: <HDIcon {...props} />,
    pauserollPlay: <PauserollResumeIcon {...props} />,
    pauserollPlayDark: <PauserollResumeDarkIcon {...props} />,
    remoteControl: <RemoteControl {...props} />,
    remoteControlActive: <RemoteControlActive {...props} />,
    flag: <Flag {...props} />,
    flagActive: <FlagActive {...props} />,
    pins: <PinsIcon {...props} />, /** for highlight != flagActive */
    chat: <Chat {...props} />,
    chatActive: <ChatActive {...props} />,
    playlist: <PlaylistIcon {...props} />,
    'playlist-active': <PlaylistActiveIcon {...props} />,
    previous: <PreviousIcon {...props} />,
    heart: <HeartIcon {...props} />,
    'heart-active': <HeartActiveIcon {...props} />,
    infos: <InfosIcon {...props} />,
    'infos-active': <InfosIconActive {...props} />,
    /**
     * TV
     */
    'tv-back-to-start': <TvBackStartIcon {...props} />,
    'tv-back-to-start-active': <TvBackStartIconActive {...props} />,
    'tv-pause': <TvPauseIcon {...props} />,
    'tv-pause-active': <TvPauseIconActive {...props} />,
    'tv-play': <TvPlayIcon {...props} />,
    'tv-play-active': <TvPlayIconActive {...props} />,
    'tv-replay': <TvReplayIcon {...props} />,
    'tv-replay-active': <TvReplayIconActive {...props} />,
    'tv-back-to-live': <TvBackLiveIcon {...props} />,
    'tv-back-to-live-active': <TvBackLiveIconActive {...props} />,
    'tv-next': <TvNextIcon {...props} />,
    'tv-next-active': <TvNextIconActive {...props} />,
    'tv-tracks': <TvTracksIconOutline {...props} />,
    'tv-tracks-active': <TvTracksIconActive {...props} />,
    'tv-stop': <TvStopIcon {...props} />,
    'tv-stop-active': <TvStopIconActive {...props} />,
    'tv-playlist': <TvPlaylistIcon {...props} />,
    'tv-playlist-active': <TvPlaylistActiveIcon {...props} />,
    'tv-zapping': <TVZappingIcon {...props} />,
    'tv-zapping-active': <TVZappingActiveIcon {...props} />,
    'tv-pauserollPlay': <PauserollResumeDarkIcon {...props} />,
    'tv-pauserollPlay-active': <PauserollResumeIcon {...props} />,
    'tv-previous': <TVPreviousIcon {...props} />,
    'tv-previous-active': <TVPreviousActiveIcon {...props} />,
    'tv-forward-4': <TvForward4Icon {...props} />,
    'tv-forward-8': <TvForward8Icon {...props} />,
    'tv-forward-16': <TvForward16Icon {...props} />,
    'tv-forward-32': <TvForward32Icon {...props} />,
    'tv-forward-64': <TvForward64Icon {...props} />,
    'tv-rewind-4': <TvRewind4Icon {...props} />,
    'tv-rewind-8': <TvRewind8Icon {...props} />,
    'tv-rewind-16': <TvRewind16Icon {...props} />,
    'tv-rewind-32': <TvRewind32Icon {...props} />,
    'tv-rewind-64': <TvRewind64Icon {...props} />,
    'tv-infos': <TvInfoIcon {...props} />,
    'tv-infos-active': <TvInfoIconActive {...props} />,
    'tv-heart': <TvHeartIcon {...props} />,
    'tv-heart-active': <TvHeartActiveIcon {...props} />
  };
  return ICONS[name] || '';
};
