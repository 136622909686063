import { Fragment, h } from 'preact';
import { useEffect } from 'preact/hooks';
import Program from './Program';
import { connect } from '../../hoc';
import Scrollable from '../../hoc/scrollable';
import { ZAPPING_NAME } from '../wrappers/Zap/constants';
import { usePanelCloseCallback } from '../wrappers/Zap/PanelLiveOption';
import { getList } from '../../utils/list';
import { RadioGroup } from '../../utils/RadioGroup';

function Programs({ activeTab, list, activeChannel, activeChannelId, currentTab, id }) {
  const { channels = [] } = list[activeTab] || {};

  const close = usePanelCloseCallback();
  useEffect(() => new RadioGroup([]), [
    activeTab, activeChannel, activeChannelId,
    list.length, list.reduce((acc, { channels: chan }) => `${acc},${chan.map(({ id: src }) => src).join(',')}`, '')
  ]);

  useEffect(() => {
    if (!channels.length) close(currentTab);
  }, [channels.length]);

  return (
    <Scrollable forceInnerScroll>
      <Fragment>
        {channels.map((metadata, i) => (
          <Program
            metadata={metadata}
            active={[activeChannelId, id].includes(metadata.id)}
            activeTabMetadata={channels[activeChannel] || null}
            currentTab={currentTab}
            programNumber={i}
            channels={channels}
          />
        ))}
      </Fragment>
    </Scrollable>
  );
}

const selector = ({
  media: { duration, id },
  highlights: { imagesHL },
  ui: {
    panelLiveOption: { currentTab }
  },
  playlist: { playlist },
  recommendations: { recommendations },
  zapping: {
    list,
    current: { tabIndex: activeTab, activeChannel, activeChannelId },
    metaOpened
  }
}) => ({
  activeTab: currentTab === ZAPPING_NAME ? activeTab : 0,
  activeChannel,
  metaOpened,
  highlights: imagesHL.length ? imagesHL : [],
  currentTab,
  duration,
  list: getList({ currentTab, list, playlist, recommendations, imagesHL, duration }),
  activeChannelId,
  id
});

export default connect(selector)(Programs);
