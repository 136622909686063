/* eslint-disable no-underscore-dangle */

import { NEVER, Subject } from 'rxjs';

export class ReplayUntilSubject extends Subject {
  /**
   * @param _bufferSize The size of the buffer to replay on subscription
   * @param _until$ An Observable that will clear buffer on emition
   *
   * */
  constructor(
    _bufferSize = Infinity,
    _until$ = NEVER
  ) {
    super();
    this._buffer = [];
    this._bufferSize = Math.max(1, _bufferSize);
    this._until$ = _until$;

    this._until$.subscribe(() => { this._buffer = []; });
  }

  next(value) {
    const { _closed, _buffer } = this;
    if (!_closed) {
      _buffer.push(value);
    }
    super.next(value);
  }

  /** @internal */
  _subscribe(subscriber) {
    const subscription = this._innerSubscribe(subscriber);

    const { _buffer } = this;
    // We use a copy here, so reentrant code does not mutate our array while we're
    // emitting it to a new subscriber.
    const copy = _buffer.slice();
    for (let i = 0; i < copy.length && !subscriber.closed; i += 1) {
      subscriber.next(copy[i]);
    }

    this._checkFinalizedStatuses(subscriber);

    return subscription;
  }
}
