import { USER_REQUEST_MEDIA_LOAD } from '../../types';
import { HIGHLIGHTS_NAME, PLAYLIST_NAME, RECO_NAME, ZAPPING_NAME } from '../components/wrappers/Zap/constants';
import { mapTitleMetadata } from './tools';

export const formatHighlightToChannels = (imagesHL, duration) => [
  {
    channels: imagesHL.map(({ highlightedAt: preTitle, additionalTitle, imageUrl: image, programDistance }) => ({
      logo: null,
      image,
      title: additionalTitle,
      preTitle,
      programDistance,
      duration
    })).reverse()
  }
];

export const formatPlaylistToChannels = (playlist) => [
  {
    channels: playlist.map(({ src: id, config, cardInfo: { iconChannel, iconCsa, title, subTitle, image } }) => ({
      id,
      logo: iconChannel,
      image,
      title,
      preTitle: subTitle,
      iconCsa,
      config
    }))
  }
];

export const getList = ({ currentTab, list, playlist = [], recommendations = [], imagesHL, duration }) => {
  switch (currentTab) {
    case ZAPPING_NAME:
      return list;
    case PLAYLIST_NAME:
      return formatPlaylistToChannels(playlist);
    case RECO_NAME:
      return formatPlaylistToChannels(recommendations);
    case HIGHLIGHTS_NAME:
      return formatHighlightToChannels(imagesHL, duration);
    default:
      return [];
  }
};

export const loadProgram = ({
  metadata, currentTab, channels,
  title, preTitle, additionalTitle,
  list, programNumber, player,
  source
}) => {
  if ([ZAPPING_NAME, PLAYLIST_NAME, RECO_NAME].includes(currentTab)) {
    const { id: src, name, config: defaultConfig } = metadata;
    let config = {};
    switch (currentTab) {
      case ZAPPING_NAME:
        config = {
          diffusion: { mode: 'live_from_zapping' },
          tracking: {
            zoneProvenance: 'zapette_player',
            playProvenance: 'zapette'
          }
        };
        break;
      case PLAYLIST_NAME:
      case RECO_NAME:
        config = {
          /* position is the human readable version of the index, hence the +1 */
          diffusion: {
            mode: currentTab === PLAYLIST_NAME ? 'tunnel' : RECO_NAME, position: programNumber + 1,
            length: channels.length
          },
          tracking: {
            zoneProvenance: `${currentTab}_player`,
            playProvenance: currentTab
          },
          ...mapTitleMetadata(list[programNumber], { title, preTitle, additionalTitle }),
          startTimecode: 0
        };
        break;
      default:
        break;
    }
    player.userEvents$.next({
      action: USER_REQUEST_MEDIA_LOAD,
      value: {
        src,
        config: {
          ...defaultConfig,
          name,
          intro: false,
          autostart: true,
          pageProvenance: '',
          positionVignette: '',
          ...config
        }
      },
      source
    });
  }
};
