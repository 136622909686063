/* eslint-disable react/no-unknown-property */
import { h } from 'preact';
import SVGWrapper from '../../SVGWrapper';

function TvHeartActiveIcon({ height = 60, width = 60 }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
    >
      <mask id="mask0_9234_39783" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="1" y="1" width="30" height="30">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16 31C24.2843 31 31 24.2843 31 16C31 7.71573 24.2843 1 16 1C7.71573 1 1 7.71573 1 16C1 24.2843 7.71573 31 16 31ZM12.3636 9C12.9466 8.99999 14.0905 9.11683 15.1957 9.91247C15.4692 10.1094 15.7403 10.3478 16 10.6364C16.2582 10.3495 16.5277 10.1124 16.7996 9.91671C17.9063 9.12014 19.0525 9.00834 19.6364 9.00834C21.0909 9.00834 24 10.2447 24 13.7356C24 16.9671 19.43 20.9599 16.6355 23.0739C16.2583 23.3593 15.7417 23.3593 15.3645 23.0739C12.57 20.9588 8 16.9587 8 13.7273C8 10.2364 10.9091 9.00002 12.3636 9Z" fill="white" />
      </mask>
      <g mask="url(#mask0_9234_39783)">
        <rect width="32" height="32" fill="black" />
        <rect width="32" height="32" fill="white" />
      </g>
    </SVGWrapper>
  );
}

export default TvHeartActiveIcon;
