import { h } from 'preact';
import { useRef, useState, useEffect, useMemo } from 'preact/hooks';
import { usePlayerContext, useStyles } from '../../hooks';
import { connect, withBreakPoints } from '../../hoc';
import { Label, Titles } from '../common';
import {
  TITLE_WRAPPER,
  HEADER_WRAPPER,
  MAIN_TITLE_TRANSITION,
  LOGO_WRAPPER
} from './styles';
import { UI_FORCE_VISIBILITY } from '../../../store/types';
import { LIVE_FILL_COLOR, PRIMARY_FILL_COLOR, OVERLAY_COLOR_OPAQUE, PRIMARY_TEXT_COLOR } from '../../theme/colors';
import ViewerLiveInfo from './ViewerLiveInfo';
import { computeVideoTitle } from '../../../utils/videoMetaUtils';
import { selfOrKeyObject } from '../../../utils';

const TITLE_SWITCH_DELAY = 2000;
const COMING_NEXT_DELAY = 2300;

function HeaderInfos({
  containerVisible,
  isLive,
  isDVR,
  isAd,
  showTitle,
  forceShowTitle,
  videoLogo,
  numberViewers,
  displayedViewers,
  showViewers,
  large,
  small,
  extraSmall,
  hidden,
  showCountdown,
  mobile,
  isFullscreen,
  isEmbed,
  isTv,
  width,
  medium,
  startOverTimeshifting,
  liveLabel,
  videoAdditionalTitle, videoTitle, videoPreTitle, subTitle, pubLabel
}) {
  const player = usePlayerContext();
  const transition = useRef(null);
  const [switchTitles, setSwitchTitles] = useState(false);
  const setStyles = useStyles();

  const { computedTitle, computedSubtitle } = useMemo(
    () => computeVideoTitle(videoTitle, subTitle, videoPreTitle, videoAdditionalTitle),
    [videoTitle, subTitle, videoPreTitle, videoAdditionalTitle]
  );

  useEffect(() => {
    if (!transition.current && containerVisible && !isAd) {
      transition.current = setTimeout(
        () => {
          setSwitchTitles(true);
          transition.current = null;
        },
        TITLE_SWITCH_DELAY
      );

      player.store.dispatch({
        type: UI_FORCE_VISIBILITY,
        payload: {
          forceVisible: !mobile
        } /* don't force visibility on mobile */
      });
    }
  }, [containerVisible]);

  useEffect(() => switchTitles && setTimeout(() => {
    setSwitchTitles(false);
    player.store.dispatch({
      type: UI_FORCE_VISIBILITY,
      payload: { forceVisible: false }
    });
  }, COMING_NEXT_DELAY), [switchTitles]);

  if (hidden) return null;

  const shouldShowInfo = !isAd
    && showTitle
    && !showCountdown
    && (isEmbed || isDVR || isFullscreen || isTv || forceShowTitle);
  const LABEL_VIEWERS_BLOCK_STYLE = { display: 'flex', flexDirection: (extraSmall && !isFullscreen) ? 'column' : 'row' };

  const shouldDisplayLogo = Boolean(selfOrKeyObject(videoLogo, 'url')) && shouldShowInfo && isLive && !isAd;

  return (
    <div style={setStyles(HEADER_WRAPPER)}>
      {shouldDisplayLogo && (
        <div style={setStyles(LOGO_WRAPPER)}>
          <img
            src={videoLogo?.url || videoLogo}
            alt={videoLogo?.alt || 'channel logo'}
            style={{ width: '100%' }}
          />
        </div>
      )}
      <div className="ftv-magnetoscope--live-label" style={{ flexBasis: 'content' }}>

        <div style={LABEL_VIEWERS_BLOCK_STYLE}>
          { (!pubLabel || !isAd) && isLive && (

          <Label
            message={liveLabel}
            style={!startOverTimeshifting
              ? { backgroundColor: LIVE_FILL_COLOR, color: PRIMARY_TEXT_COLOR }
              : { backgroundColor: PRIMARY_FILL_COLOR, color: OVERLAY_COLOR_OPAQUE }}
          />
          )}

          {showViewers && numberViewers && displayedViewers
        && (isLive && !isAd) && (
        <ViewerLiveInfo
          connected={numberViewers}
          isFullscreen={isFullscreen}
          mobile={mobile}
          extraSmall={extraSmall}
          medium={medium}
          small={small}
          large={large}
          size={setStyles({
            'small+extraSmall': { size: 20 },
            medium: { size: 24 },
            'large+extraLarge': { size: 30 }
          }).size}
        />
          )}
        </div>

        {shouldShowInfo && (
        <div style={TITLE_WRAPPER}>
          <div style={{ opacity: 1, width: width * (2 / 3), ...MAIN_TITLE_TRANSITION }}>
            <Titles
              title={computedTitle}
              subTitle={computedSubtitle}
              titleStyle={setStyles({
                extraSmall: { fontSize: '16px', fontWeight: 700, marginBottom: '4px' }
              })}
              subTitleStyle={setStyles({
                extraSmall: { fontSize: '12px', fontWeight: 400 }
              })}
            />
          </div>
        </div>
        )}
      </div>
    </div>
  );
}

const selector = ({
  media: {
    videoLogo, isAd, isLive, isDVR, numberViewers,
    timeshifting: { startOverTimeshifting },
    videoAdditionalTitle, videoTitle, videoPreTitle, subTitle, isTv
  },
  ad: { showCountdown },
  ui: {
    showTitle, forceShowTitle, isFullscreen, originUrl, forceVisible, introEnabled, isIntro, showViewers, displayedViewers,
    liveLabel, pubLabel
  }
}) => ({
  showTitle,
  forceShowTitle,
  videoLogo,
  displayedViewers,
  numberViewers,
  showViewers,
  isAd,
  isLive,
  isDVR,
  isTv,
  showCountdown,
  isFullscreen,
  isEmbed: Boolean(originUrl),
  forceVisible,
  introEnabled,
  isIntro,
  startOverTimeshifting,
  liveLabel,
  videoAdditionalTitle, videoTitle, videoPreTitle, subTitle, pubLabel
});

export default withBreakPoints(connect(selector)(HeaderInfos));
