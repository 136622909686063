/* svg fills */
export const PRIMARY_FILL_COLOR = '#F8F8F8';
export const SECONDARY_FILL_COLOR = '#FFFFFF';
export const PLACEHOLDER_FILL_COLOR = '#383838';
export const LABELS_FILL_COLOR = '#DDDDDD';
export const LIVE_FILL_COLOR = '#e61e00';
export const VIEWER_FILL_COLOR = 'transparent';

/* text */
export const PRIMARY_TEXT_COLOR = '#F2F2F2';
export const SECONDARY_TEXT_COLOR = '#B4B4B4';
export const THIRD_TEXT_COLOR = '#5687F8';
export const FOURTH_TEXT_COLOR = '#DDDDDD';
export const METADATA_TEXT_COLOR = '#B9B9B9';

export const DARK_PRIMARY_TEXT_COLOR = '#000000';

/* scrollbar */
export const SCROLLBAR_BACKGROUND_COLOR = '#919191';

/* sliders */
export const SLIDER_OFF_COLOR = 'rgba(243, 243, 243, 0.5)';
export const SLIDER_BUFFERED_COLOR = 'rgba(242, 242, 242, 0.62)';
export const SLIDER_TIMELINE_DEFAULT_COLOR = 'rgba(242, 242, 242, 0.62)';
export const SLIDER_TIMELINE_LIVE_COLOR = '#E61E00';

/* overlays */
export const OVERLAY_COLOR_TRANSPARENT = 'rgba(0, 0, 0, 0.8)';
export const OVERLAY_COLOR_OPAQUE = '#282828';
export const OVERLAY_SCREEN_BACKGROUND = 'rgba(0, 0, 0, 0.75)';
export const OVERLAY_SCREEN_OPAQUE_BACKGROUND = 'black';

/* gradient overlay */
export const MAIN_GRADIENT = 'linear-gradient(rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%)';
export const MAIN_GRADIENT_TV = 'linear-gradient(rgba(35, 35, 35, 0.00) 0%, rgba(35, 35, 35, 0.8) 100%)';
export const MAIN_GRADIENT_AD = 'linear-gradient(180deg, rgba(35, 35, 35, 0.9) 0%, rgba(35, 35, 35, 0) 20%, rgba(35, 35, 35, 0) 80%, rgba(35, 35, 35, 0.9) 100%)';

/* buttons */
export const FTV_BLUE_COLOR = '#2C69F6';
export const FTV_DISABLED_COLOR = '#E61E00';
export const FTV_ACTIVE_COLOR = '#1FB538';

export const FTV_DARK_PRIMARY_BACKGROUND_COLOR = '#F0F1F3';
export const FTV_DARK_PRIMARY_ACTIVE_BACKGROUND_COLOR = 'rgba(240, 241, 243, 0.7)';
export const FTV_DARK_PRIMARY_HOVER_BACKGROUND_COLOR = 'rgba(240, 241, 243, 0.85)';

/* Tabs */
export const TAB_HEADER_BACKGROUND = '#30343B';
export const TAB_HEADER_ACTIVE_BACKGROUND = 'rgba(256, 256, 256, 0.2)';
export const ACTIVE_ITEM_BACKGROUND = 'rgb(67, 75, 81)'; // #434B51

export const TRANSITION_DURATION = 350;

export const ZAPPING_BACKGROUND_TRANSPARENT = 'rgba(0,0,0,0.9)';
export const PANEL_LIVE_OPTION_BACKGROUND = '#172128';

export const SIDEBAR_SIZE_MEDIUM = 40;
export const SIDEBAR_SIZE_LARGE = 30;
