/* eslint-disable class-methods-use-this */
import TracksController from './index';
import { AUDIO_TRACKS_RECONCILED } from '../../store/types';

export default class AudioTracksController extends TracksController {
  constructor({ tracks$, activeRenderer$, events$, isAd$, platform }) {
    super({ tracks$, activeRenderer$, events$, isAd$, platform });

    TracksController.createReconcilerStream({
      type: 'audioTracks',
      activeRenderer$,
      availableTracks$: this.availableTracks$
    }).subscribe(({ type, tracks, videoEl }) => {
      TracksController.reconcile({ type, tracks, videoEl });
      events$.next(AUDIO_TRACKS_RECONCILED);
    });
  }

  setTrack(renderer, selectedTrack) {
    const selected = renderer.setAudioTrack(selectedTrack);
    return selected;
  }

  findTrack(tracks, index) {
    return (tracks.find((track) => track.index === index) || tracks[0]);
  }
}
