/* eslint-disable class-methods-use-this */
import {
  BehaviorSubject,
  filter,
  map,
  switchMap,
  Subject,
  merge,
  withLatestFrom,
  combineLatest,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  startWith,
  throttleTime,
  take,
  first,
  skip
} from 'rxjs';
import { CAUSE_NO_RECO, CAUSE_PLAYER_NO_FULLSCREEN, CAUSE_PLAYER_TOO_SMALL, CAUSE_USER_CLOSED } from './types';
import { PLAYBACK_END, PLAYBACK_PLAY, PLAYBACK_PLAYING } from '../../store/types';
import { BLOCK_RECOMMENDATIONS, NEED_PLAYLIST, NEED_RECO } from '../../types';
import { takeUntilWithLast } from '../../utils/rx-utils';
import { Disposable } from '..';
import { getAdjacentPlaylistMedias } from '../../utils';
import { DIFFUSION_MODE_SINGLE } from '../../settings/types';
import { RECO_NAME } from '../../ui/components/wrappers/Zap/constants';

export class RecommendationController extends Disposable {
  constructor(player) {
    super();
    const receivedRecommendationsFromIntegration$ = new BehaviorSubject(false);

    this.isDisplayableWithCause$ = new BehaviorSubject({ isDisplayable: false, cause: CAUSE_NO_RECO });
    this.recommendations$ = new BehaviorSubject([]);
    this.shouldDisplay$ = new Subject();
    this.isLegacy$ = new BehaviorSubject(false);

    RecommendationController.createRecommendationConfigDetectionStream(player.isLive$, this.recommendations$)
      .subscribe(receivedRecommendationsFromIntegration$);

    this.createDisplayRecommendationsWithCauseStream(
      receivedRecommendationsFromIntegration$,
      {
        dimensions$: player.domController.dimensions$,
        fullscreenChange$: player.fullscreenController.fullscreenChange$,
        userEvents$: player.userEvents$
      }
    ).subscribe(this.isDisplayableWithCause$);

    RecommendationController.createMediasEndDetectionStream(player, this.isDisplayableWithCause$)
      .pipe(withLatestFrom(this.isLegacy$))
      .subscribe(RecommendationController.restart.bind(this, player));

    // Log final object in the console with debug: true
    this.createShouldDisplayStream(
      player.mediaController.medias$,
      player.events$,
      player.rendererController.currentTime$,
      this.isDisplayableWithCause$,
      this.recommendations$
    )
      .subscribe(this.shouldDisplay$);

    this.shouldDisplay$.pipe(
      withLatestFrom(this.isLegacy$),
      filter(([, isLegacy]) => isLegacy),
      map(([e]) => e)
    ).subscribe(player.shouldDisplayReco$);

    // Log final object in the console with debug: true isDisplayableWithCause
    this.isDisplayableWithCause$
      .pipe(map((payload) => ({ name: 'recommendations', payload })))
      .subscribe((e) => player.events$.next(e));

    player.mediaController.medias$.pipe(
      skip(1),
      switchMap(() => player.events$.pipe(
        filter((e) => e === PLAYBACK_PLAY),
        first()
      ))
    ).subscribe(this.reset.bind(this));

    merge(
      this.createNeedRecoStream(player),
      RecommendationController.createNeedPlaylist(player)
    ).subscribe((e) => player.events$.next(e));
  }

  reset() {
    this.recommendations$.next([]);
  }

  static restart(player, [{ isDisplayableWithCause: { isDisplayable } }, isLegacy]) {
    if (!isLegacy || !isDisplayable) {
      player.restart({ autostart: false });
    }
  }

  createNeedRecoStream(player) {
    const { mediaController: { medias$ }, playerConfig$, events$ } = player;
    const trigger$ = events$.pipe(
      filter((e) => e === PLAYBACK_PLAY),
      switchMap(() => this.recommendations$.pipe(filter((r) => r.length === 0)))
    );

    return medias$.pipe(
      withLatestFrom(playerConfig$),
      filter(([{ meta: { id }, isLive }, playerConfig]) => {
        const { playlist: { playlist } } = player.store.getState();
        const isSingleMedia = !playlist.length && (!playerConfig?.diffusion?.mode || playerConfig?.diffusion?.mode === DIFFUSION_MODE_SINGLE);
        const isPlaylistLastMedia = playlist.length && !getAdjacentPlaylistMedias(playlist, id).nextMedia;

        return !isLive && (isSingleMedia || isPlaylistLastMedia);
      }),
      map(([{ meta: { id } }, { videoProductId }]) => ({
        name: NEED_RECO,
        payload: { src: id, videoProductId }
      })),
      switchMap((event) => trigger$.pipe(
        map(() => event),
        first()
      ))
    );
  }

  static createNeedPlaylist({ medias$, playerConfig$ }) {
    return medias$.pipe(
      withLatestFrom(playerConfig$),
      filter(([{ isLive }, playerConfig]) => !isLive && playerConfig?.diffusion?.mode === RECO_NAME),
      map(([{ meta: { id } }, { videoProductId }]) => ({
        name: NEED_PLAYLIST,
        payload: { src: id, videoProductId }
      }))
    );
  }

  static createRecommendationConfigDetectionStream(isLive$, recommendations$) {
    return isLive$.pipe(
      filter((isLive) => !isLive),
      switchMap(() => recommendations$.pipe(
        filter((recommendations) => recommendations && recommendations.length),
        map(() => true)
      ))
    );
  }

  // eslint-disable-next-line class-methods-use-this
  createDisplayRecommendationsWithCauseStream(
    isFlowEnabled$,
    { dimensions$, fullscreenChange$, userEvents$ }
  ) {
    return combineLatest([isFlowEnabled$, dimensions$, fullscreenChange$.pipe(startWith(false))]).pipe(
      distinctUntilChanged((
        [prevFlow, { containerIsExtraSmallScreen: prevXSmall }, prevFS],
        [nextFlow, { containerIsExtraSmallScreen: nextXSmall }, nextFS]
      ) => prevFlow === nextFlow && prevXSmall === nextXSmall && prevFS === nextFS),
      map(([isFlowEnabled, { containerIsExtraSmallScreen }, isFullscreen]) => {
        if (!isFlowEnabled) { return { isDisplayable: false, cause: CAUSE_NO_RECO }; }
        if (containerIsExtraSmallScreen) return { isDisplayable: false, cause: CAUSE_PLAYER_TOO_SMALL };
        if (!isFullscreen) return { isDisplayable: false, cause: CAUSE_PLAYER_NO_FULLSCREEN };

        return { isDisplayable: true };
      }),
      takeUntilWithLast(userEvents$.pipe(filter((e) => e.action === BLOCK_RECOMMENDATIONS)), { isDisplayable: false, cause: CAUSE_USER_CLOSED })
    );
  }

  static createMediasEndDetectionStream({ events$, playerConfig$, currentVideo$ }, isDisplayableWithCause$) {
    return events$.pipe(
      withLatestFrom(playerConfig$, currentVideo$, isDisplayableWithCause$),
      filter(([event, { next }]) => event === PLAYBACK_END && !next),
      map(([, , currentVideo, isDisplayableWithCause]) => ({ currentVideo, isDisplayableWithCause }))
    );
  }

  endStream(medias$, events$) {
    return medias$
      .pipe(switchMap(() => events$.pipe(
        filter((e) => e === PLAYBACK_END),
        map(() => true),
        startWith(false)
      )));
  }

  createShouldDisplayStream(media$, events$, currentTime$, isDisplayableWithCause$, recommendations$) {
    const afterClosingCredits$ = media$.pipe(
      map(({ video: { closing_credits: { timecode } } }) => timecode),
      filter(Boolean),
      switchMap((timecode) => currentTime$.pipe(
        throttleTime(50),
        map((currentTime) => currentTime > timecode),
        distinctUntilChanged()
      ))
    );

    const reset$ = recommendations$.pipe(
      filter((r) => !r.length),
      map(() => ({ shouldDisplay: false, cause: CAUSE_NO_RECO }))
    );

    const shouldDisplay$ = merge(
      this.endStream(media$, events$),
      afterClosingCredits$
    ).pipe(
      switchMap((displayTiming) => isDisplayableWithCause$.pipe(
        map(({ isDisplayable, cause }) => ({ shouldDisplay: displayTiming && isDisplayable, cause }))
      ))
    );

    return media$.pipe(
      switchMap(() => events$.pipe(
        filter((e) => e === PLAYBACK_PLAYING),
        take(1),
        switchMap(() => merge(shouldDisplay$, reset$)),
        distinctUntilKeyChanged('shouldDisplay')
      ))
    );
  }
}
