import { SUBTITLE_AVAILABLE, SUBTITLE_SWITCHED, SUBTITLES_CHANGED } from '../types';

const initialState = {
  active: false,
  position: {
    left: '50%',
    top: '95%'
  },
  alignment: {
    horizontal: 'end',
    vertical: 'center'
  },
  tags: []
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SUBTITLE_AVAILABLE:
    case SUBTITLE_SWITCHED: {
      const { subtitleSelected } = payload;
      return { ...state, active: subtitleSelected !== -1 };
    }
    case SUBTITLES_CHANGED: {
      const { position, alignment, tags } = payload;
      return { ...state, position, alignment, tags };
    }
    default: {
      return state;
    }
  }
};
