import { Fragment, h } from 'preact';
import { useCallback, useMemo } from 'preact/hooks';
import { usePlayerContext, useStyles } from '../../../hooks';
import { PANEL_CLOSE_BUTTON, PANEL_CLOSE_BUTTON_SIZE, PANEL_CLOSE_LABEL, PANEL_LIVE_OPTION_WRAPPER_STYLE_OPENED, PANEL_TITLE } from './styles';
import { Delayed, withBreakPoints } from '../../../hoc';
import { Button } from '../../common';
import { USER_CLICK, USER_KEYBOARD, USER_PANEL_CLOSED, USER_PANEL_LIVE_OPTIONS_CLOSE } from '../../../../types';
import { isValidateKeyboard } from '../../../utils/keyboard';
import { TOGGLE_SHOW_PANNEL_LIVE_OPTION } from '../../../../store/types';

import ProgramTypeSwitch from '../../zap/ProgramTypeSwitch';

import Programs from '../../zap/Programs';
import { CHAT_NAME, HIGHLIGHTS_NAME, PLAYLIST_NAME, ZAPPING_NAME, RECO_NAME, INFOS_NAME } from './constants';
import Chat from './Chat';
import InfosPanel from './InfosPanel';
import { TRANSITION_DURATION } from '../../../theme/colors';
import '../../../theme/styles/panel.css';

const TITLES_MAPPING = {
  [ZAPPING_NAME]: 'Tous les directs',
  [HIGHLIGHTS_NAME]: 'Les temps forts',
  [CHAT_NAME]: 'Live chat',
  [PLAYLIST_NAME]: 'Du même programme',
  [RECO_NAME]: 'Recommandé pour vous',
  [INFOS_NAME]: 'Infos'
};

export function usePanelCloseCallback() {
  const player = usePlayerContext();

  return useCallback((name, fromKeyboard) => {
    player.store.dispatch({
      type: TOGGLE_SHOW_PANNEL_LIVE_OPTION,
      payload: {
        show: false,
        currentTab: ''
      }
    });
    player.userEvents$.next({
      action: USER_PANEL_LIVE_OPTIONS_CLOSE,
      value: false,
      source: fromKeyboard ? USER_KEYBOARD : USER_CLICK
    });

    player.userEvents$.next({
      action: USER_PANEL_CLOSED,
      value: { name, showPanel: false },
      source: fromKeyboard ? USER_KEYBOARD : USER_CLICK
    });
  });
}

function PanelLiveOption({ panelLiveOption, smallContainer, containerWidth, containerHeight, panelHeight }) {
  const setStyles = useStyles();

  const close = usePanelCloseCallback();

  const isPortrait = containerWidth < containerHeight;

  const panelWrapperStyle = useMemo(() => {
    if (smallContainer) {
      return {
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: 0,
        borderRadius: 0
      };
    }

    if (isPortrait) {
      return {
        position: 'absolute',
        width: '100%',
        height: panelHeight || '100%',
        bottom: 0
      };
    }

    return {
      top: 0,
      height: panelHeight || '100%'
    };
  }, [smallContainer, isPortrait, panelHeight]);

  const chooseComponent = (name) => {
    switch (name) {
      case CHAT_NAME:
        return <Chat />;
      case INFOS_NAME:
        return <InfosPanel />;
      default:
        return (
          <Fragment>
            <ProgramTypeSwitch />
            <Programs />
          </Fragment>
        );
    }
  };

  return (
    <Delayed isMounted={panelLiveOption.show} shouldDelay delay={TRANSITION_DURATION}>
      {() => (
        <div
          style={{
            ...setStyles(PANEL_LIVE_OPTION_WRAPPER_STYLE_OPENED),
            ...panelWrapperStyle,
            animationTimingFunction: 'ease-in-out',
            animation: panelLiveOption.show
              ? `ftv-magneto-panel-live-option-show${(isPortrait && '-portrait') || ''} ${TRANSITION_DURATION}ms`
              : `ftv-magneto-panel-live-option-hide${(isPortrait && '-portrait') || ''} ${TRANSITION_DURATION}ms`
          }}
          id="panel-live-option"
        >
          <Button
            type="icon"
            openingMode="click"
            label="fermer"
            ariaLabel="fermer"
            forceLabel
            disableFocus={false}
            last
            onClick={(e) => {
              if (e instanceof PointerEvent) {
                close(panelLiveOption.currentTab, false);
              }
            }}
            onKeyDown={(e) => {
              if (isValidateKeyboard(e)) {
                close(panelLiveOption.currentTab, true);
              }
            }}
            icon="close"
            xs
            iconRef={null}
            name="btn-close-panel-live-options"
            ariaHidden={false}
            size={setStyles(PANEL_CLOSE_BUTTON_SIZE).width}
            zone="top"
            styleOverride={setStyles(PANEL_CLOSE_BUTTON)}
            labelStyle={setStyles(PANEL_CLOSE_LABEL)}
          />
          <div
            id="panel"
            style={{
              height: (!smallContainer && panelHeight) || '100%',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <div style={setStyles(PANEL_TITLE)}>
              {
                TITLES_MAPPING[panelLiveOption.currentTab]
                || TITLES_MAPPING[ZAPPING_NAME]
              }
            </div>
            {
              chooseComponent(panelLiveOption.currentTab)
            }
          </div>
        </div>
      )}
    </Delayed>
  );
}

export default withBreakPoints(PanelLiveOption);
