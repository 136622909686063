const TAG_NAME = {
  c: 'p',
  i: 'i',
  b: 'b',
  u: 'u',
  ruby: 'ruby',
  rt: 'rt',
  v: 'span',
  lang: 'span'
};

export default class VTTParser {
  static parse(cue) {
    if (!cue) return null;

    const AUTO_POSITION = {
      left: 0,
      center: 50,
      right: 100
    };
    const style = {
      alignment: {
        horizontal: cue.align,
        vertical: cue.line === 'auto' ? 'end' : cue.lineAlign
      },
      position: {
        left: cue.position === 'auto' ? AUTO_POSITION[cue.align ?? 'center'] : cue.position,
        top: cue.line === 'auto' ? 100 : cue.line
      }
    };

    const regex = /(?:<([^/])([^>]+)?>)?([^<]+)(?:<\/.>)?/g;
    const matches = [...cue.text.matchAll(regex)];
    const tags = matches.reduce((prev, [, tag, classes, text]) => {
      const cssClasses = classes
        ? [...classes.split('.').filter(Boolean)]
        : ['bg_black'];

      // If no background color is set by the cue, we default to black
      if (!cssClasses.some((c) => c.includes('bg'))) {
        cssClasses.push('bg_black');
      }

      return [...prev, {
        tagName: TAG_NAME[tag] ?? 'p',
        cssClasses,
        text: text.trim()
      }];
    }, []);

    return { tags, ...style };
  }
}
