/* eslint-disable react/no-unknown-property */
import { h } from 'preact';
import SVGWrapper from '../../SVGWrapper';

function TvInfoIcon({ height = 32, width = 32 }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
    >
      <mask id="mask0_9005_41239" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="1" y="1" width="30" height="30">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16 31C24.2843 31 31 24.2843 31 16C31 7.71573 24.2843 1 16 1C7.71573 1 1 7.71573 1 16C1 24.2843 7.71573 31 16 31ZM22.9091 16C22.9091 19.8161 19.8157 22.9091 16.0003 22.9091C12.1843 22.9091 9.09091 19.816 9.09091 16C9.09091 12.184 12.1843 9.09091 16.0003 9.09091C19.8157 9.09091 22.9091 12.1839 22.9091 16ZM24 16C24 20.4186 20.4182 24 16.0003 24C11.5818 24 8 20.4186 8 16C8 11.5814 11.5818 8 16.0003 8C20.4182 8 24 11.5814 24 16ZM16.7273 13.2749C16.7273 13.6765 16.4017 14.0021 16 14.0021C15.5983 14.0021 15.2727 13.6765 15.2727 13.2749C15.2727 12.8732 15.5983 12.5476 16 12.5476C16.4017 12.5476 16.7273 12.8732 16.7273 13.2749ZM16 15.0891C15.6988 15.0891 15.4545 15.3331 15.4545 15.634V18.9079C15.4545 19.2088 15.6988 19.4528 16 19.4528C16.3012 19.4528 16.5455 19.2088 16.5455 18.9079V15.634C16.5455 15.3331 16.3012 15.0891 16 15.0891Z" fill="white" />
      </mask>
      <g mask="url(#mask0_9005_41239)">
        <rect width="32" height="32" fill="black" />
        <rect width="32" height="32" fill="white" />
      </g>
    </SVGWrapper>
  );
}

export default TvInfoIcon;
