import { Subject, merge, of, NEVER, combineLatest, ReplaySubject } from 'rxjs';
import { map, startWith, scan, switchMap, bufferCount, filter, withLatestFrom, distinctUntilChanged, share, debounceTime } from 'rxjs/operators';
import { VIDEO_START } from '../../types';
import { MID_CP_MARGIN } from './types';

export default class MidrollManager {
  constructor(player) {
    const { events$: playerEvent$ } = player;
    const { medias$ } = player.mediaController;

    this.nbMidroll$ = new ReplaySubject(1);
    this.currentTimecodeIndex$ = new ReplaySubject(1).pipe(startWith(-1));
    this.cuepoints$ = new Subject();
    this.lastMidroll$ = new Subject();
    this.cuepoints$ = new ReplaySubject(1);
    this.nextCuepoint$ = new ReplaySubject(1);
    this.adBreakTriggered$ = new ReplaySubject(1);
    this.nbMidrollLeft$ = new ReplaySubject(1);
    this.viewedTime$ = new ReplaySubject(1);
    this.playedMidrolls$ = new ReplaySubject(1);

    MidrollManager.createCuepointStream({ nbMidroll$: this.nbMidroll$, medias$ })
      .subscribe(this.cuepoints$);

    MidrollManager.createAdBreakStream(medias$, this.nextCuepoint$, player)
      .subscribe(this.adBreakTriggered$);

    MidrollManager.createNbMidrollLeftStream(this.cuepoints$, this.nbMidroll$)
      .subscribe(this.nbMidrollLeft$);
    MidrollManager.createViewedTimeStream(playerEvent$, player, this.adBreakTriggered$, this.nbMidrollLeft$)
      .subscribe(this.viewedTime$);

    /* We want to send a different time before midroll for the first adBreak, then default to the minimal duration between midroll */
    this.timeBeforeMidroll$ = MidrollManager.createTimeBeforeMidrollStream(medias$, this.adBreakTriggered$);
    this.isAdBreakPlayable$ = MidrollManager.createIsAdBreakPlayableStream(this.viewedTime$, this.timeBeforeMidroll$);

    MidrollManager
      .createNextCuepointStream(this.isAdBreakPlayable$, this.cuepoints$, playerEvent$, player, this.nbMidrollLeft$)
      .subscribe(({ nextCuepoint, currentTimecodeIndex }) => {
        this.currentTimecodeIndex$.next(currentTimecodeIndex);
        this.nextCuepoint$.next(nextCuepoint);
      });

    MidrollManager.markUsedTimecodes(this.lastMidroll$, this.cuepoints$)
      .subscribe(this.cuepoints$);

    MidrollManager.createPlayedMidroll(medias$, this.adBreakTriggered$)
      .subscribe(this.playedMidrolls$);
  }

  static createPlayedMidroll(medias$, adBreakTriggered$) {
    return medias$.pipe(
      switchMap(() => adBreakTriggered$.pipe(
        scan((midrollCount) => midrollCount + 1, 0),
        startWith(0)
      ))
    );
  }

  static createNbMidrollLeftStream(cuepoints$) {
    return cuepoints$.pipe(
      map((cuepoints) => cuepoints.reduce(
        (midrollLeft, cues) => (cues.length > 0 ? midrollLeft + 1 : midrollLeft),
        0
      ))
    );
  }

  static findCurrentCuepointIndex = (cuepoints, currentTime) => cuepoints.findIndex(
    (timecodes) => timecodes.some((tc) => tc >= currentTime)
  );

  static markUsedTimecodes(lastMidroll$, cuepoints$) {
    return lastMidroll$.pipe(
      filter(({ ended }) => ended),
      withLatestFrom(cuepoints$),
      map(([{ cuepoint }, cuepoints]) => {
        const currentTimecodeIndex = MidrollManager.findCurrentCuepointIndex(cuepoints, cuepoint);
        const newCuepoints = [...cuepoints];
        newCuepoints[currentTimecodeIndex] = [];
        return newCuepoints;
      })
    );
  }

  static createCuepointStream({ nbMidroll$, medias$ }) {
    return nbMidroll$.pipe(
      withLatestFrom(medias$.pipe(map(({ markers: { pub: { midroll: { timecodes: cuepoints } } } }) => cuepoints))),
      filter(([nbMidroll, cuepoints]) => nbMidroll > 0 && cuepoints.length > 0),
      map(([, cuepoints]) => cuepoints)
    );
  }

  static createTimeBeforeMidrollStream(medias$, adBreakTriggered$) {
    return medias$.pipe(
      switchMap(({ markers: { pub: { midroll: { cappingStart, cappingMidroll } } } }) => merge(
        of(cappingStart),
        adBreakTriggered$.pipe(map(() => cappingMidroll))
      ))
    );
  }

  static createAdBreakStream(medias$, nextCuepoint$, player) {
    /* reset on each media to avoid distinctUntilChanged collision */
    return medias$.pipe(
      switchMap(() => player.rendererController.currentTime$.pipe(
        bufferCount(2, 1),
        filter(([previous, current]) => current > previous && current < previous + 1),
        map(([, current]) => current),
        withLatestFrom(nextCuepoint$),
        filter(([currentTime, nextCuepoint]) => nextCuepoint && currentTime > nextCuepoint),
        map(([, nextCuepoint]) => nextCuepoint),
        distinctUntilChanged()
      )),
      share()
    );
  }

  static createIsAdBreakPlayableStream(viewedTime$, timeBeforeMidroll$) {
    return viewedTime$.pipe(
      startWith(false),
      withLatestFrom(timeBeforeMidroll$),
      switchMap(([viewedTime, timeBeforeMidroll]) => of(viewedTime > timeBeforeMidroll)),
      distinctUntilChanged()
    );
  }

  static createViewedTimeStream(playerEvent$, player, adBreakTriggered$, nbMidrollLeft$) {
    return merge(
      playerEvent$.pipe(filter((e) => e === VIDEO_START)),
      adBreakTriggered$
    ).pipe(
      withLatestFrom(nbMidrollLeft$, (_, nbMidroll) => nbMidroll > 0),
      switchMap((enabled) => {
        if (!enabled) return NEVER;

        return player.rendererController.currentTime$.pipe(
          bufferCount(2, 1),
          filter(([previous, current]) => current > previous && current < previous + 1),
          scan((acc, [previous, current]) => acc + (current - previous), 0)
        );
      })
    );
  }

  static createNextCuepointStream(isAdBreakPlayable$, cuepoints$, playerEvent$, player, nbMidrollLeft$) {
    const disableDuringSeek$ = merge(
      playerEvent$.pipe(filter((e) => e === 'seeked'), map(() => true)),
      playerEvent$.pipe(filter((e) => e === 'seeking'), map(() => false))
    ).pipe(startWith(true));

    return combineLatest({
      enabled: isAdBreakPlayable$,
      seeked: disableDuringSeek$,
      nbMidrollLeft: nbMidrollLeft$
    }).pipe(
      switchMap(({ enabled, seeked, nbMidrollLeft }) => {
        if (!enabled || !seeked || nbMidrollLeft === 0) {
          return of({
            nextCuepoint: null,
            currentTimecodeIndex: -1
          });
        }

        return combineLatest({ cpts: cuepoints$, ct: player.rendererController.currentTime$ }).pipe(
          debounceTime(30),
          map(({ cpts, ct }) => ({
            nextCuepoint: cpts.flat().find((cuepoint) => cuepoint >= ct - MID_CP_MARGIN),
            currentTimecodeIndex: MidrollManager.findCurrentCuepointIndex(cpts, ct - MID_CP_MARGIN)
          }))
        );
      })
    );
  }
}
