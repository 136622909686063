import { h } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';
import { connect } from '../../../hoc';
import { useStyles } from '../../../hooks';

import {
  PANEL_PROGRAM_DESCRIPTION,
  PANEL_PROGRAM_METADATA
} from './styles';
import Scrollable from '../../../hoc/scrollable';

function InfosPanel({
  description,
  playtime,
  endDate
}) {
  const setStyles = useStyles();

  /*
    We compute the size available for the description :
    We remove from the total height of the panel container :
     * The footer (duration + close button)
    We also remove the padding from the description itself.
    That gives us the max allow height for it.
  */
  const panelRef = useRef({ clientHeight: 0 });
  const footerRef = useRef({ clientHeight: 0 });
  const [descriptionMaxHeight, setDescriptionMaxHeight] = useState(0);

  useEffect(
    () => {
      setDescriptionMaxHeight(
        panelRef.current.clientHeight - footerRef.current.clientHeight - (setStyles(PANEL_PROGRAM_DESCRIPTION).padding ?? 0) * 2
      );
    },
    [footerRef.current.clientHeight, panelRef.current.clientHeight]
  );
  /***/

  return (
    <div ref={panelRef} style={{ height: '100%' }}>
      <div
        id="panel"
        style={{
          display: 'block'
        }}
      >
        <div name="infos-view">
          <div
            style={{ ...setStyles(PANEL_PROGRAM_DESCRIPTION) }}
          >
            <Scrollable forceInnerScroll maxHeight={descriptionMaxHeight}>
              <div>{description}</div>
            </Scrollable>
          </div>
          <div name="infos-view-footer" ref={footerRef}>
            <div style={setStyles(PANEL_PROGRAM_METADATA)}>
              {playtime}
              {' '}
              | disponible jusqu&rsquo;au
              {' '}
              {endDate}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const selector = ({
  media: {
    description,
    playtime,
    endDate
  }
}) => ({
  description,
  playtime,
  endDate
});

export default connect(selector)(InfosPanel);
