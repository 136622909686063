import { h } from 'preact';
import { useCallback, useRef } from 'preact/hooks';
import { usePlayerContext, useStyles } from '../../hooks';
import { ZAP_PROGRAM_DEFAULT_IMAGE_SVG, ZAP_PROGRAM_GRADIENT_BACKDROP, ZAP_PROGRAM_MAIN_TITLE, ZAP_PROGRAM_PREVIEW, ZAP_PROGRAM_SECOND_TITLE, ZAP_PROGRAM_TITLES, ZAP_PROGRAM_WRAPPER } from './styles';
import { connect, withBreakPoints } from '../../hoc';
import { USER_CLICK, USER_KEYBOARD } from '../../../types';
import { PROGRAM_CLICKED_EVENTS } from '../wrappers/Zap/constants';
import { ACTIVE_ITEM_BACKGROUND } from '../../theme/colors';
import { loadProgram } from '../../utils/list';
import { isValidateKeyboard } from '../../utils/keyboard';

function Program({
  metadata,
  smallContainer,
  active,
  activeProgramMetadata = {},
  currentTab,
  programNumber,
  channels,
  title,
  preTitle,
  additionalTitle
}) {
  const player = usePlayerContext();
  const setStyles = useStyles();
  const refContainer = useRef(null);

  const PROGRAM_VISUAL_STYLE = {
    ...ZAP_PROGRAM_GRADIENT_BACKDROP,
    backgroundImage: metadata?.image
      ? `${ZAP_PROGRAM_GRADIENT_BACKDROP.backgroundImage},url("${metadata.image}")`
      : `${ZAP_PROGRAM_GRADIENT_BACKDROP.backgroundImage},url("${ZAP_PROGRAM_DEFAULT_IMAGE_SVG}")`
  };

  const handleClick = useCallback((fromKeyboard) => {
    const source = fromKeyboard ? USER_KEYBOARD : USER_CLICK;

    player.userEvents$.next({
      action: PROGRAM_CLICKED_EVENTS[currentTab],
      value: {
        id: metadata.id,
        previousChannel: activeProgramMetadata.name,
        currentChannel: metadata.name,
        ...metadata
      },
      source
    });

    loadProgram({
      metadata, source, channels, list: channels, programNumber,
      currentTab, player, title, preTitle, additionalTitle
    });
  }, [metadata.id, activeProgramMetadata?.name]);

  return (
    <div
      ref={refContainer}
      style={{
        ...setStyles(ZAP_PROGRAM_WRAPPER),
        ...active && ({ background: ACTIVE_ITEM_BACKGROUND })
      }}
      role="radio"
      aria-checked={active ? 'true' : 'false'}
      onKeyDown={(e) => {
        if (isValidateKeyboard(e)) {
          handleClick(true);
          setTimeout(() => refContainer.current?.focus(), 500);
        }
      }}
      onClick={handleClick}
      tabIndex={0}
      className="ftv-magneto-pointer ftv-magneto--selectable"
    >
      <div style={setStyles(ZAP_PROGRAM_PREVIEW)}>
        <div style={{ ...PROGRAM_VISUAL_STYLE }}>
          {metadata.logo && (
            <img
              width={smallContainer ? 30 : 44}
              height={smallContainer ? 30 : 44}
              src={metadata.logo}
              alt={metadata.name}
              draggable={false}
              onDragStart={(e) => e.preventDefault()}
            />
          )}
        </div>
      </div>
      <div style={setStyles(ZAP_PROGRAM_TITLES)}>
        <div style={setStyles(ZAP_PROGRAM_MAIN_TITLE)}>{metadata.title}</div>
        <div style={setStyles(ZAP_PROGRAM_SECOND_TITLE)}>
          {metadata.preTitle ? `${metadata.preTitle} ` : ''}
          {metadata.additionalTitle}
        </div>
      </div>
    </div>
  );
}

const selector = ({
  playlist: { playlist },
  media: { title, preTitle, additionalTitle }
}) => ({
  playlist,
  title,
  preTitle,
  additionalTitle
});

export default withBreakPoints(connect(selector)(Program));
