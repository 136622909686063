import { BehaviorSubject, ReplaySubject, Subject, of, pipe, merge, fromEvent, interval, timer, race, NEVER, combineLatest } from 'rxjs';
import {
  map,
  filter,
  debounceTime,
  switchMap,
  distinctUntilChanged,
  startWith,
  take,
  mergeMap,
  distinctUntilKeyChanged
} from 'rxjs/operators';
import { Destroyable$ } from '../../utils/rx-utils';
import error from '../../error';
import HTML_EVENTS from './events';
import { RENDERER_CREATED, RENDERER_DISPOSE, RENDERER_LIVE_STREAM_DETECTED } from './types';
import { TIMESHIFTING_TYPE_AUTO } from '../timeshifting/types';
import { RENDERER_NETWORK_ERROR } from '../../error/definitions';
import { PLAYBACK_TIMEUPDATE } from '../../store/types';
import { isTagUnique } from '../../utils';
import { TV_PLATFORMS } from '../media/types';

const ERROR_RECOVERY_TIMEOUT = 10000;

export default class GenericRenderer extends Destroyable$ {
  constructor(media, playerConfig, videoUnlocker, isAd$) {
    super();
    const { config, video: { is_DVR: isDVR = false } = {} } = media;
    this.config = config;
    this.isDVR = isDVR; /* keep a reference for edge cases */
    this.videoUnlocker = videoUnlocker;
    this.tagElement = videoUnlocker?.getVideoTag(this.config) || null;

    this.state$ = new BehaviorSubject(RENDERER_CREATED);
    this.errors$ = new Subject();
    this.suspendDuringMidroll$ = new BehaviorSubject(false);
    this.videoOrAudioEventsIn$ = new Subject();
    this.videoOrAudioEventsOut$ = this.suspendDuringMidroll$.pipe(switchMap((pause) => (pause ? NEVER : this.videoOrAudioEventsIn$)));
    this.duration$ = new ReplaySubject(1).pipe(startWith(0)); // initial value for lives
    this.currentTime$ = new ReplaySubject(1);
    this.muted$ = new BehaviorSubject(false);
    this.volume$ = new BehaviorSubject(0);
    this.buffered$ = new Subject();
    this.audioTracks$ = new ReplaySubject(1);
    this.textTracks$ = new ReplaySubject(1);
    this.bitrate$ = new Subject();
    this.playbackRate$ = new Subject().pipe(filter(Boolean)); /* filter out playbackrate 0 when buffering */
    this.startPosition = playerConfig?.startTimecode || 0;
    this.isLive$ = new BehaviorSubject(media.isLive);
    this.timeshiftable$ = new BehaviorSubject(media.video?.timeshiftable);
    this.qualities$ = new BehaviorSubject([]);
    this.id3Tags$ = new Subject();
    this.stream$ = new Subject();
    this.engine$ = new ReplaySubject(1);

    this.isPreroll$ = isAd$.pipe(distinctUntilKeyChanged('isAd'), map(({ isAd, position, willProbablyPlayAnAd }) => isAd && willProbablyPlayAnAd && position === 'PREROLL'));
    this.isMidroll$ = isAd$.pipe(distinctUntilKeyChanged('isAd'), map(({ isAd, position, willProbablyPlayAnAd }) => isAd && willProbablyPlayAnAd && position === 'MIDROLL'));

    this.state$.pipe(
      filter((evt) => evt === RENDERER_LIVE_STREAM_DETECTED),
      map(() => true)
    ).subscribe(this.isLive$);

    this.errors$.safeSubscribe(this, this.handleError.bind(this));

    if (TV_PLATFORMS.includes(this.config.platform) && !!media.video.duration) {
      this.duration$.next(media.video.duration);
    }
  }

  init() {
    /* All renderers must implement createErrorStream */
    const errors$ = new Subject();

    this.createErrorStream()
      .pipe(GenericRenderer.mapToError())
      .safeSubscribe(this, errors$);

    this.suspendDuringMidroll$.pipe(switchMap((onAd) => (onAd ? NEVER : errors$)))
      .safeSubscribe(this, this.errors$);

    this.createAvailableQualitiesStream().safeSubscribe(this, this.qualities$);
  }

  async setupQuanteec(rendererName, media) {
    const {
      config: {
        quanteec: quanteecOption
      },
      quanteec
    } = media;

    if (quanteecOption && quanteec && !isTagUnique(media.config)) {
      const Quanteecimport = await import(`@quanteec/quanteec-plugin/quanteec-${rendererName}js.min`);
      const Quanteec = Quanteecimport.default;
      // eslint-disable-next-line no-new
      return new Quanteec({ ...quanteec, cellularActivated: false }, this.engine);
    }

    return null;
  }

  handleError({ error: { fatal } }) {
    /* override for renderer specific error handling */
    if (fatal) this.dispose();
  }

  dispose() {
    this.state$.next(RENDERER_DISPOSE);
    this.destroy(); /* clean-up subscriptions from Destroyable$ */

    /* hard-reset video tag to prevent memory leaks */
    this.tagElement.src = '';
    this.tagElement.load();
  }

  play() {
    return this.tagElement.play();
  }

  pause() {
    /* don't trigger unecessary pause if video is already paused */
    return (!this.tagElement.paused && this.tagElement.pause());
  }

  seek(position) {
    this.tagElement.currentTime = position;
  }

  mute(value) {
    this.tagElement.muted = value;
  }

  volume(value) {
    this.tagElement.volume = value;
  }

  speed(value) {
    this.tagElement.playbackRate = value;
  }

  attachTracks() { } /* eslint-disable-line */
  startLoad() { } /* eslint-disable-line */
  setTextTrack() { } /* eslint-disable-line */
  createAvailableQualitiesStream() { return NEVER; }  /* eslint-disable-line */
  setVideoQuality() { } /* eslint-disable-line */

  static createOperator(eventName, valueGetter = () => { }, startValue = valueGetter()) {
    return pipe(
      filter((evt) => evt === eventName),
      map(() => valueGetter()),
      startWith(startValue)
    );
  }

  static mapElementContentEvents(events, tagElement) {
    return events.map((eventName) => fromEvent(tagElement, eventName));
  }

  static createContentEventStream(tagElement) {
    return merge(...GenericRenderer.mapElementContentEvents(HTML_EVENTS, tagElement))
      .pipe(map((evt) => evt.type));
  }

  static getBuffered(tagElement) {
    const { duration } = tagElement;
    if (duration > 0) {
      for (let i = 0; i < tagElement.buffered.length; i += 1) {
        const firstPosition = tagElement.buffered.start(tagElement.buffered.length - 1 - i);
        if (firstPosition <= tagElement.currentTime) {
          return (tagElement.buffered.end(tagElement.buffered.length - 1 - i) / duration) * 100;
        }
      }
    }
    return 0;
  }

  static createCurrentTimeStream(tagElement, suspendDuringMidroll$) {
    return suspendDuringMidroll$.pipe(switchMap((isMidroll) => (isMidroll ? NEVER
      : merge(
        interval(50),
        /* to update currentTime on 'seekend' to fix UI timeline flickering */
        fromEvent(tagElement, 'seeked')
      ).pipe(
        map(() => tagElement.currentTime),
        startWith(0),
        distinctUntilChanged()
      ))));
  }

  createDurationStream(tagElement, isLive$, timeshiftable$, suspendDuringMidroll$) {
    return combineLatest([isLive$, timeshiftable$]).pipe(
      distinctUntilChanged(([isLive], [nextIsLive]) => isLive === nextIsLive),
      switchMap(([isLive, timeshiftingType]) => {
        if (isLive && !timeshiftingType) {
          return of(0);
        }
        if (isLive && timeshiftingType === TIMESHIFTING_TYPE_AUTO) {
          return this.createTimeshiftingDuration();
        }

        return merge(
          isLive && timeshiftingType ? of(0) : NEVER, // only for timeshifting manuel
          suspendDuringMidroll$.pipe(switchMap((isMidroll) => (isMidroll ? NEVER : fromEvent(tagElement, 'durationchange')
            .pipe(
              filter(() => Number.isFinite(tagElement.duration)),
              map(() => tagElement.duration)
            )
          )))
        );
      })
    );
  }

  // eslint-disable-next-line class-methods-use-this
  createTimeshiftingDuration() {
    /** We initiate the duration at 0,
     * as some controllers (ie. freewheel) can need a duration before we start loading the media,
     * thus, before any canplaythrough event.
    */
    throw new Error('Method need to be implemented');
  }

  static mapToError() {
    return pipe(debounceTime(50), map((e) => ({ error: error(e) })));
  }

  createRecoveryFailedStream(errors$) {
    /**
      * generic utility to check wether an error with canRecover: true has successfuly
      * recovered -> ensure tagElement is playing via timeupdate events
      * If recovery fails, map to fatal error
      */
    return errors$.pipe(
      mergeMap((err) => of(err).pipe(
        filter(GenericRenderer.isRecoverableError),
        switchMap((e) => GenericRenderer.oversightErrorRecovery(e, this.tagElement))
      ), 1 /* concurency */)
    );
  }

  static isRecoverableError({ canRecover, type }) {
    return canRecover || type === RENDERER_NETWORK_ERROR.type;
  }

  static oversightErrorRecovery(e, tagElement) {
    return race(
      fromEvent(tagElement, PLAYBACK_TIMEUPDATE).pipe(take(1), map(() => true)),
      timer(ERROR_RECOVERY_TIMEOUT).pipe(take(1), map(() => false))
    ).pipe(
      filter((recovered) => !recovered),
      map(() => ({ ...e, fatal: true, canRecover: false }))
    );
  }
}
