export const ON_TOKEN_READY = 'onTokenReady';
export const ORANGEOTT = 'orangeott';
export const SFROTT = 'sfrott';
export const SMARTTV = 'smarttv';
export const TV_PLATFORMS = [ORANGEOTT, SMARTTV, SFROTT];
export const TV = 'tv';

export const DEFAULT_PARAMS_MAP = {
  force: { },
  transmit: ['connection_type', 'diffusion_mode', 'device', 'app_version', 'firmware'],
  sysInfoToSend: []
};
export const PLATFORM_FORCED_MAP = {
  [ORANGEOTT]: {
    ...DEFAULT_PARAMS_MAP,
    force: { browser: 'orange', device_type: TV }
  },
  [SFROTT]: {
    ...DEFAULT_PARAMS_MAP,
    force: { browser: 'sfr', device_type: TV }
  },
  [SMARTTV]: {
    ...DEFAULT_PARAMS_MAP,
    force: { device_type: TV },
    sysInfoToSend: ['browser', 'browserVersion', 'os', 'osVersion']
  },
  default: DEFAULT_PARAMS_MAP
};

export const CAPABILITY_DRM = 'drm2';
