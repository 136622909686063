import { delay, distinctUntilChanged, filter, map, Subject, switchMap, take, withLatestFrom } from 'rxjs';
import TracksController from './index';
import VTTParser from './parsers/vtt';
import TTMLParser from './parsers/ttml';
import { TEXT_TRACKS_RECONCILED } from '../../store/types';
import { RESET_TRACKS_MODE } from './types';

export default class TextTrackController extends TracksController {
  constructor({ tracks$, activeRenderer$, events$, rendererStream$, isAd$ }) {
    super({ tracks$, activeRenderer$ });

    this.currentSubtitle$ = new Subject();
    this.lastSubtitle$ = new Subject();

    TracksController.createReconcilerStream({
      type: 'textTracks',
      activeRenderer$,
      availableTracks$: this.availableTracks$
    }).subscribe(({ type, tracks, videoEl }) => {
      TracksController.reconcile({ type, tracks, videoEl });
      events$.next(TEXT_TRACKS_RECONCILED);
    });

    /* empty container on each new video */
    activeRenderer$.subscribe(() => this.emptySubtitleContainer());

    TextTrackController.createBeforeAdStream({ isAd$, currentSubtitle$: this.currentSubtitle$ }).subscribe(this.lastSubtitle$);
    this.lastSubtitle$.subscribe(() => this.emptySubtitleContainer());

    TextTrackController.createAfterAdStream({ isAd$, lastSubtitle$: this.lastSubtitle$ }).subscribe(this.currentSubtitle$);

    TextTrackController.createResetTextTrackModeStream({ activeRenderer$, rendererStream$, activeTrack$: this.activeTrack$ })
      .subscribe(TextTrackController.resetTextTrackMode);
  }

  static createBeforeAdStream({ isAd$, currentSubtitle$ }) {
    return isAd$
      .pipe(
        distinctUntilChanged(),
        filter(({ isAd }) => isAd),
        withLatestFrom(currentSubtitle$),
        map(([, currentSubtitle]) => currentSubtitle)
      );
  }

  static createAfterAdStream({ lastSubtitle$, isAd$ }) {
    return lastSubtitle$
      .pipe(
        filter(Boolean),
        switchMap((lastSubtitle) => isAd$.pipe(
          filter(({ isAd }) => !isAd),
          take(1),
          map(() => lastSubtitle)
        ))
      );
  }

  static createResetTextTrackModeStream({ rendererStream$, activeTrack$, activeRenderer$ }) {
    return rendererStream$.pipe(
      filter((e) => e === RESET_TRACKS_MODE),
      withLatestFrom(activeTrack$, activeRenderer$),
      delay(50),
      map(([, index, { renderer: { tagElement } }]) => ({ index, tagElement }))
    );
  }

  // eslint-disable-next-line class-methods-use-this
  findTrack(tracks, index) {
    return tracks.find((track) => track.index === index)
      || ({ index: -1, label: 'desactive', language: null });
  }

  setTrack(renderer, index) {
    const { tagElement } = renderer;
    renderer.setTextTrack(index);
    this.handleTextTrack(index, tagElement);
  }

  emptySubtitleContainer() {
    this.currentSubtitle$.next({ tags: [{ text: '', cssClasses: [], tagName: 'p' }], alignment: {}, position: {} });
  }

  static resetTextTrackMode({ index, tagElement }) {
    Object.keys(tagElement.textTracks)
      .forEach((key, i) => {
        // eslint-disable-next-line no-param-reassign
        tagElement.textTracks[i].mode = 'hidden';
        const track = tagElement.textTracks[key];
        track.mode = TextTrackController.setTrackMode(track.index === index);
      });
  }

  handleTextTrack(index, videoEl) {
    this.emptySubtitleContainer();
    Object.keys(videoEl.textTracks)
      .forEach((key, i) => {
        // eslint-disable-next-line no-param-reassign
        videoEl.textTracks[i].mode = 'hidden';
        const track = videoEl.textTracks[key];
        const selected = track.index === index;
        track.oncuechange = selected ? this.handleCueChange.bind(this, track) : null;

        track.mode = TextTrackController.setTrackMode(selected);
      });
  }

  handleCueChange(textTrack) {
    this.emptySubtitleContainer();
    const cue = textTrack.activeCues?.[0];

    if (cue?.text || cue?.isd || cue?.cueHTMLElement) {
      let parsedCue = '';

      if (cue.isd) {
        parsedCue = TTMLParser.parse(cue.isd);
      } else if (cue.text) {
        parsedCue = VTTParser.parse(cue);
      } else if (cue.cueHTMLElement) {
        parsedCue = cue.cueHTMLElement;
        parsedCue.style = {}; /* remove top level styles */
      }
      if (parsedCue) {
        this.currentSubtitle$.next(parsedCue);
      }
    }
  }

  static setTrackMode(selected) {
    return selected ? 'hidden' : 'disabled';
  }
}
