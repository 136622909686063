/* eslint-disable no-param-reassign */
export class RadioGroup {
  constructor(groupNode) {
    this.groupNode = groupNode;

    this.radioButtons = [];

    this.firstRadioButton = null;
    this.lastRadioButton = null;

    const rbs = Array.from(document.getElementsByClassName('ftv-magneto--selectable'));

    rbs.forEach((rb) => {
      rb.tabIndex = -1;

      rb.addEventListener('keydown', this.handleKeydown.bind(this));
      rb.addEventListener('click', this.handleClick.bind(this));
      rb.addEventListener('focus', RadioGroup.handleFocus);
      rb.addEventListener('blur', RadioGroup.handleBlur);

      this.radioButtons.push(rb);

      if (!this.firstRadioButton) {
        this.firstRadioButton = rb;
        this.firstRadioButton.tabIndex = 0;
      }
      this.lastRadioButton = rb;
    });
  }

  selectItem(currentItem, setChecked = false) {
    if (setChecked) {
      this.radioButtons.forEach((rb) => {
        rb.setAttribute('aria-checked', 'false');
        rb.tabIndex = -1;
      });
      currentItem.setAttribute('aria-checked', 'true');
      currentItem.tabIndex = 0;
    }
    currentItem.focus();
  }

  /**
   * @param {*} currentItem
   * @param {*} previous
   * @param {*} exitOnLastOrFirst
   * @returns flag
   */
  setCheckedItem(currentItem, previous, exitOnLastOrFirst = false) {
    const checkCurrent = previous ? this.firstRadioButton : this.lastRadioButton;
    if (currentItem === checkCurrent) {
      if (exitOnLastOrFirst) {
        return false;
      }
      this.selectItem(previous ? this.lastRadioButton : this.firstRadioButton);
    } else {
      const index = this.radioButtons.indexOf(currentItem);
      this.selectItem(this.radioButtons[previous ? index - 1 : index + 1]);
    }
    return true;
  }

  /* EVENT HANDLERS */

  handleKeydown(event) {
    const tgt = event.currentTarget;
    let flag = false;

    switch (event.key) {
      case ' ':
      case 'Enter':
        this.selectItem(tgt, true);
        flag = true;
        break;

      case 'Up':
      case 'ArrowUp':
      case 'Left':
      case 'ArrowLeft':
        this.setCheckedItem(tgt, true);
        flag = true;
        break;

      case 'Down':
      case 'ArrowDown':
      case 'Right':
      case 'ArrowRight':
        this.setCheckedItem(tgt, false);
        flag = true;
        break;

      case 'Tab': {
        flag = this.setCheckedItem(tgt, event.shiftKey, true);
        break;
      }

      default:
        break;
    }

    if (flag) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  handleClick(event) {
    this.selectItem(event.currentTarget, true);
    event.currentTarget.classList.remove('focus');
  }

  static handleFocus(event) {
    event.currentTarget.classList.add('focus');
  }

  static handleBlur(event) {
    event.currentTarget.classList.remove('focus');
  }
}
